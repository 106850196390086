import Vue from 'vue';
import Vuex from 'vuex';
// we must import these modules before we import the storeBuilder via typex conversion
// see https://medium.com/swlh/properly-typed-vuex-stores-427bf4c6a3d1
// typex stores
import './modules/AuthStore';
import './modules/AvatarStore';
import './modules/CampaignStore';
import './modules/PaymentStore';
// @logan disabled for now
// import './modules/CatalogMerchantStore'
import './modules/GiftCardStore';
import './modules/NotificationStore';
import './modules/UserStore';
import './modules/AppStore';
import './modules/GratitudeStore';
import './modules/OfferStore';
// @logan plugins for vuex
import createLogger from 'vuex/dist/logger';
import VuexPersistence from 'vuex-persist';
import { storeBuilder } from './RootState';
// @logan tell vue to use Vuex
Vue.use(Vuex);
// @logan local storage hack, not sure works still with vuex-typex
// @alejandro it works with vuex-typex, we just need to use the module names defined in RootState interface.
const vuexLocalStorage = new VuexPersistence({
    storage: window.localStorage,
    // modules: ['campaign', 'app']
    modules: ['app']
});
const debug = process.env.NODE_ENV !== 'production';
// @logan added tooling support here, which is the plugins
// @logan new store stuff
export default storeBuilder.vuexStore({
    strict: debug,
    plugins: debug ? [createLogger(), vuexLocalStorage.plugin] : [vuexLocalStorage.plugin]
});
// @logan old store stuff
// export default new Vuex.Store({
//     modules: {
//         CatalogMerchants
//     },
//     strict: debug,
//     plugins: debug ? [createLogger(), vuexLocalStorage.plugin] : [vuexLocalStorage.plugin]
// })
