import { storeBuilder } from "@/store/RootState";
import { CompanyClient } from "../../service/CompanyClient";
import { CompanyModel } from "@gd-mono/gd-common";
import * as notificationStore from "./NotificationStore";
import _ from "lodash";
/**
 * @description enum to ID what view state the user is in, editing, viewing or creating
 * @author Logan Hendershot
 * @date 06/24/2022
 * @export
 * @enum {number}
 */
export var COMPANY_VIEW_STATE;
(function (COMPANY_VIEW_STATE) {
    // rome-ignore lint/style/useEnumInitializers: <explanation>
    COMPANY_VIEW_STATE[COMPANY_VIEW_STATE["EDITING"] = 0] = "EDITING";
    // rome-ignore lint/style/useEnumInitializers: <explanation>
    COMPANY_VIEW_STATE[COMPANY_VIEW_STATE["VIEWING"] = 1] = "VIEWING";
    // rome-ignore lint/style/useEnumInitializers: <explanation>
    COMPANY_VIEW_STATE[COMPANY_VIEW_STATE["CREATING"] = 2] = "CREATING";
})(COMPANY_VIEW_STATE || (COMPANY_VIEW_STATE = {}));
/**
 * @description Initial company state, contains the authed logged in users company as well as a 'target' company or just company and then all companies.
 *
 * Initialized to be either a Company object or undefined, depending on if they exist. This way it is declared in state but not defined.
 * @author Logan Hendershot
 * @date 06/23/2022
 * @export
 * @class CompanyState
 */
export class CompanyState {
    viewState = COMPANY_VIEW_STATE.VIEWING;
    loading = false;
    authUserCompany = undefined;
    company = undefined;
    allCompanies = undefined;
}
// Companies module builder
const companiesModule = storeBuilder.module("companies", new CompanyState());
// Companies client is the interface from the Company common code that has been implemented by this class CompaniesClient, mainly defines read/write etc.
const companiesClient = new CompanyClient();
// Getters
const getterFunctions = {
    getAllCompanies: companiesModule.read((state) => state.allCompanies, "getAllCompanies"),
    getAuthUserCompany: companiesModule.read((state) => state.authUserCompany, "getAuthUserCompany"),
    getCompany: companiesModule.read((state) => state.company, "getCompany"),
    getApprovedCompanies: companiesModule.read((state) => state.allCompanies?.filter((av) => av.approved), "getApprovedCompanies"),
    getUnapprovedCompanies: companiesModule.read((state) => state.allCompanies?.filter((av) => !av.approved), "getUnapprovedCompanies"),
    getLoading: companiesModule.read((state) => state.loading, "getLoading"),
    isCreating: companiesModule.read((state) => state.viewState === COMPANY_VIEW_STATE.CREATING, "isCreating"),
    isEditing: companiesModule.read((state) => state.viewState === COMPANY_VIEW_STATE.EDITING, "isEditing"),
    isViewing: companiesModule.read((state) => state.viewState === COMPANY_VIEW_STATE.VIEWING, "isViewing"),
    getLocations: companiesModule.read((state) => state.company.locations, "getLocations"),
};
export const getters = {
    get getAllCompanies() {
        return getterFunctions.getAllCompanies();
    },
    get getAuthUserCompany() {
        return getterFunctions.getAuthUserCompany();
    },
    get getCompany() {
        return getterFunctions.getCompany();
    },
    get getApprovedCompanies() {
        return getterFunctions.getApprovedCompanies();
    },
    get getUnapprovedCompanies() {
        return getterFunctions.getUnapprovedCompanies();
    },
    get getLoading() {
        return getterFunctions.getLoading();
    },
    get isCreating() {
        return getterFunctions.isCreating();
    },
    get isEditing() {
        return getterFunctions.isEditing();
    },
    get isViewing() {
        return getterFunctions.isViewing();
    },
    get getLocations() {
        return getterFunctions.getLocations();
    },
};
async function getAllCompaniesAction() {
    mutations.setLoading(true);
    try {
        const companies = await companiesClient.readAll();
        mutations.setAllCompanies(companies);
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error getting companies.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function getCompanyAction(_, id) {
    mutations.setLoading(true);
    if (!id) {
        mutations.setCompany(null);
        mutations.setLoading(false);
        return;
    }
    try {
        const resp = await companiesClient.read(id);
        mutations.setCompany(resp);
        mutations.setLoading(false);
        return resp;
    }
    catch (error) {
        // added this check since companyId might not be initialized, and be undefined
        // if id is defined and there is an error, show it, otherwise don't show
        if (id) {
            notificationStore.actions.pushError({
                title: "Error getting company.",
                error,
            });
        }
    }
    mutations.setLoading(false);
}
async function getAuthedUserCompanyAction(context) {
    mutations.setLoading(true);
    // context.rootState.auth.user.companyId
    try {
        const resp = await companiesClient.read(context.rootState.auth?.user?.companyId);
        mutations.setAuthedUserCompany(resp);
        mutations.setCompany(resp);
        mutations.setLoading(false);
        return resp;
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error getting company.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function createCompanyAction(context, ownerUserId) {
    mutations.setLoading(true);
    try {
        const resp = await companiesClient.create(ownerUserId, context.state.company);
        mutations.setCompany(resp);
        mutations.setViewState(COMPANY_VIEW_STATE.VIEWING);
        notificationStore.actions.pushSuccess({
            title: "Company created.",
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error creating company.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function updateCompanyAction(context) {
    mutations.setLoading(true);
    try {
        await companiesClient.update(context.state.company);
        actions.getCompany(context.state.company.id); // Need to use this action to get members and pendingMembers arrays
        mutations.setViewState(COMPANY_VIEW_STATE.VIEWING);
        notificationStore.actions.pushSuccess({
            title: "Company updated.",
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error updating company.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function requestToJoin(context, companyId) {
    mutations.setLoading(true);
    try {
        await companiesClient.requestToJoin(companyId);
        notificationStore.actions.pushSuccess({
            title: "Membership request sent.",
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error sending membership request.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function acceptCompanyMember(context, userId) {
    mutations.setLoading(true);
    try {
        await companiesClient.acceptJoinRequest(context.state.company.id, userId);
        actions.getCompany(context.state.company.id); // Need to use this action to get members and pendingMembers arrays
        notificationStore.actions.pushSuccess({
            title: "Member joined successfully",
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error admitting member.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function removeMember(context, userId) {
    mutations.setLoading(true);
    try {
        await companiesClient.removeMember(context.state.company.id, userId);
        actions.getCompany(context.state.company.id); // Need to use this action to get members and pendingMembers arrays
        notificationStore.actions.pushSuccess({
            title: "Member removed from company.",
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error removing member.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function updateOwner(context, userId) {
    mutations.setLoading(true);
    try {
        await companiesClient.updateOwner(context.state.company.id, userId);
        actions.getCompany(context.state.company.id); // Need to use this action to get members and pendingMembers arrays
        notificationStore.actions.pushSuccess({
            title: "Success setting company owner.",
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error setting company owner.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function approveCompany(context, companyId) {
    mutations.setLoading(true);
    try {
        await companiesClient.updateApproval(companyId, true);
        notificationStore.actions.pushSuccess({
            title: "Approved Company.",
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error Approving Company.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function unApproveCompany(context, companyId) {
    mutations.setLoading(true);
    try {
        await companiesClient.updateApproval(companyId, false);
        notificationStore.actions.pushSuccess({
            title: "Successfully Unapproved Company.",
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error Unapproving Company.",
            error,
        });
    }
    mutations.setLoading(false);
}
export const actions = {
    getAllCompanies: companiesModule.dispatch(getAllCompaniesAction),
    getCompany: companiesModule.dispatch(getCompanyAction),
    getAuthedUserCompanyAction: companiesModule.dispatch(getAuthedUserCompanyAction),
    createCompany: companiesModule.dispatch(createCompanyAction),
    updateCompany: companiesModule.dispatch(updateCompanyAction),
    acceptCompanyMember: companiesModule.dispatch(acceptCompanyMember),
    removeMember: companiesModule.dispatch(removeMember),
    updateOwner: companiesModule.dispatch(updateOwner),
    requestToJoin: companiesModule.dispatch(requestToJoin),
    approveCompany: companiesModule.dispatch(approveCompany),
    unApproveCompany: companiesModule.dispatch(unApproveCompany),
};
// mutations
function setAllCompanies(state, companies) {
    state.allCompanies = companies;
}
function setAuthedUserCompany(state, company) {
    state.authUserCompany = company;
}
function setCompany(state, company) {
    state.company = company;
}
function setName(state, payload) {
    state.company.name = payload;
    state.company = _.cloneDeep(state.company);
}
function setLogoUrl(state, payload) {
    state.company.logo_url = payload;
    state.company = _.cloneDeep(state.company);
}
function setUrl(state, payload) {
    state.company.url = payload;
    state.company = _.cloneDeep(state.company);
}
function setTwitterUrl(state, payload) {
    state.company.twitterUrl = payload;
    state.company = _.cloneDeep(state.company);
}
function setFacebookUrl(state, payload) {
    state.company.facebookUrl = payload;
    state.company = _.cloneDeep(state.company);
}
function setInstaUrl(state, payload) {
    state.company.instagramUrl = payload;
    state.company = _.cloneDeep(state.company);
}
function setDescription(state, payload) {
    state.company.description = payload;
    state.company = _.cloneDeep(state.company);
}
function setPhone(state, payload) {
    state.company.phone = payload;
    state.company = _.cloneDeep(state.company);
}
function setAddress(state, payload) {
    state.company.address = payload;
    state.company = _.cloneDeep(state.company);
}
function setEmail(state, payload) {
    state.company.email = payload;
    state.company = _.cloneDeep(state.company);
}
function setTags(state, payload) {
    state.company.tags = payload;
    state.company = _.cloneDeep(state.company);
}
function setCoverUrl(state, payload) {
    state.company.cover_url = payload;
    state.company = _.cloneDeep(state.company);
}
function setLocations(state, payload) {
    state.company.locations = payload;
    state.company = _.cloneDeep(state.company);
}
function initCompany(state) {
    state.company = new CompanyModel();
    state.viewState = COMPANY_VIEW_STATE.CREATING;
}
function setLoading(state, payload) {
    state.loading = payload;
}
function setViewState(state, payload) {
    state.viewState = payload;
}
function spliceLocations(state, index) {
    state.company.locations.splice(index, 1);
    state.company = _.cloneDeep(state.company);
}
function addLocations(state, payload) {
    // check if already in locations by find
    // if not found, add
    if (!state.company.locations.find((location) => payload.placeId === location.placeId)) {
        state.company.locations.push(payload);
    }
    state.company = _.cloneDeep(state.company);
}
// mutations export
export const mutations = {
    setAllCompanies: companiesModule.commit(setAllCompanies),
    setAuthedUserCompany: companiesModule.commit(setAuthedUserCompany),
    setCompany: companiesModule.commit(setCompany),
    setName: companiesModule.commit(setName),
    setLogoUrl: companiesModule.commit(setLogoUrl),
    setUrl: companiesModule.commit(setUrl),
    setTwitterUrl: companiesModule.commit(setTwitterUrl),
    setFacebookUrl: companiesModule.commit(setFacebookUrl),
    setInstaUrl: companiesModule.commit(setInstaUrl),
    setDescription: companiesModule.commit(setDescription),
    setPhone: companiesModule.commit(setPhone),
    setAddress: companiesModule.commit(setAddress),
    setEmail: companiesModule.commit(setEmail),
    setTags: companiesModule.commit(setTags),
    setCoverUrl: companiesModule.commit(setCoverUrl),
    initCompany: companiesModule.commit(initCompany),
    setLoading: companiesModule.commit(setLoading),
    setViewState: companiesModule.commit(setViewState),
    setLocations: companiesModule.commit(setLocations),
    spliceLocations: companiesModule.commit(spliceLocations),
    addLocations: companiesModule.commit(addLocations),
};
