import { render, staticRenderFns } from "./MerchantCompanyCard.vue?vue&type=template&id=2f9d0e15&scoped=true&"
import script from "./MerchantCompanyCard.vue?vue&type=script&lang=ts&"
export * from "./MerchantCompanyCard.vue?vue&type=script&lang=ts&"
import style0 from "./MerchantCompanyCard.vue?vue&type=style&index=0&id=2f9d0e15&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f9d0e15",
  null
  
)

export default component.exports