import { storeBuilder } from "@/store/RootState";
import { USER_THANKS_TYPE } from "@gd-mono/gd-common";
import GratitudeClient from "../../service/GratitudeClient";
import * as notificationStore from "./NotificationStore";
export class GratitudeState {
    allGratitudes;
}
// module builder
const gratitudeModule = storeBuilder.module("app", new GratitudeState());
// getters
const getterFunctions = {
    allGratitudes: gratitudeModule.read((state) => state.allGratitudes, "allGratitudes"),
    gratitudeImages: gratitudeModule.read((state) => state.allGratitudes.gratitudeImages.filter((grat) => grat.active), "gratitudeImages"),
    gratitudeImagesInactive: gratitudeModule.read((state) => state.allGratitudes.gratitudeImages.filter((grat) => !grat.active), "gratitudeImagesInactive"),
    gratitudeText: gratitudeModule.read((state) => state.allGratitudes.gratitudeText.filter((grat) => grat.active), "gratitudeText"),
    gratitudeTextInactive: gratitudeModule.read((state) => state.allGratitudes.gratitudeText.filter((grat) => !grat.active), "gratitudeTextInactive"),
};
export const getters = {
    get getAllGratitudes() {
        return getterFunctions.allGratitudes();
    },
    get gratitudeImages() {
        return getterFunctions.gratitudeImages();
    },
    get gratitudeImagesInactive() {
        return getterFunctions.gratitudeImagesInactive();
    },
    get gratitudeText() {
        return getterFunctions.gratitudeText();
    },
    get gratitudeTextInactive() {
        return getterFunctions.gratitudeTextInactive();
    },
    get gratitudeEmoji() {
        return getterFunctions.gratitudeTextInactive();
    },
    get gratitudeEmojiInactive() {
        return getterFunctions.gratitudeTextInactive();
    },
};
// mutations
const mutationFunctions = {
    setGratitudes(state, gratitudes) {
        state.allGratitudes = gratitudes;
    },
};
export const mutations = {
    setGratitudes: gratitudeModule.commit(mutationFunctions.setGratitudes),
};
// actions
const actionFunctions = {
    async getAllGratitudes(_) {
        try {
            const gratitudes = await GratitudeClient.readAll();
            mutations.setGratitudes({
                gratitudeImages: gratitudes.filter((grat) => grat.type === USER_THANKS_TYPE.IMAGE),
                gratitudeText: gratitudes.filter((grat) => grat.type === USER_THANKS_TYPE.TEXT),
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error getting gratitudes.",
                error,
            });
        }
    },
    async createGratitude(_, gratitude) {
        try {
            await GratitudeClient.create(gratitude);
            notificationStore.actions.pushSuccess({
                title: "Gratitude created.",
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error creating gratitude.",
                error,
            });
        }
    },
    async updateGratitude(_, gratitude) {
        try {
            await GratitudeClient.update(gratitude);
            notificationStore.actions.pushSuccess({
                title: "Gratitude updated.",
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error updating gratitude.",
                error,
            });
        }
    },
    async removeGratitude(_, id) {
        try {
            await GratitudeClient.delete(id);
            notificationStore.actions.pushSuccess({
                title: "Gratitude removed.",
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error removing gratitude.",
                error,
            });
        }
    },
};
export const actions = {
    getAllGratitudes: gratitudeModule.dispatch(actionFunctions.getAllGratitudes),
    createGratitude: gratitudeModule.dispatch(actionFunctions.createGratitude),
    updateGratitude: gratitudeModule.dispatch(actionFunctions.updateGratitude),
    removeGratitude: gratitudeModule.dispatch(actionFunctions.removeGratitude),
};
