var render = function render(){
  var _vm$offer6, _vm$offer7, _vm$offer8, _vm$offer9, _vm$offer10, _vm$offer11, _vm$offer12, _vm$offer13;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', [_c('ValidationProvider', {
    ref: "provider",
    attrs: {
      "name": "Offer Validation"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$offer, _vm$offer2, _vm$offer3, _vm$offer4, _vm$offer5;
        var errors = _ref.errors;
        return [_c('div', {
          staticClass: "my-5"
        }, [_c('h1', {
          staticClass: "display-3 mt-0"
        }, [_vm._v("Describe the Drop")]), _c('p', {
          staticClass: "lead text-muted"
        }, [_vm._v(" 20% off Winter Sale? BOGO? Free item with purchase? Describe your drop to GiftDrop users. ")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('base-input', {
          attrs: {
            "type": "text",
            "label": "Drop Title",
            "description": "The title of the drop. Usually what the drop contains.",
            "name": "Drop Title",
            "rules": "required|max:50",
            "disabled": _vm.isViewing
          },
          model: {
            value: _vm.dropName,
            callback: function callback($$v) {
              _vm.dropName = $$v;
            },
            expression: "dropName"
          }
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('base-text-area', {
          attrs: {
            "type": "text",
            "rows": 8,
            "label": "Drop Description",
            "description": "A description to provide supporting context information to the drop.",
            "name": "Drop Description",
            "rules": "required|min:5|max:1000",
            "disabled": _vm.isViewing
          },
          model: {
            value: _vm.dropDescription,
            callback: function callback($$v) {
              _vm.dropDescription = $$v;
            },
            expression: "dropDescription"
          }
        })], 1)], 1), ((_vm$offer = _vm.offer) === null || _vm$offer === void 0 ? void 0 : _vm$offer.type) === 'DISCOUNT' ? _c('div', [((_vm$offer2 = _vm.offer) === null || _vm$offer2 === void 0 ? void 0 : _vm$offer2.sub_type) !== 'DISCOUNT_TOTAL' ? _c('div', [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('base-input', {
          attrs: {
            "type": "number",
            "prepend-icon": "currency-dollar",
            "name": "Original Price",
            "label": "Original Price",
            "description": "The original value of the item, or service to be given to the user.",
            "disabled": _vm.isViewing
          },
          model: {
            value: _vm.value,
            callback: function callback($$v) {
              _vm.value = $$v;
            },
            expression: "value"
          }
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('base-input', {
          attrs: {
            "type": "number",
            "prepend-icon": "currency-dollar",
            "name": "Reduced Price",
            "label": "Reduced Price",
            "description": "The reduced price of the item, or service to be given to the user.",
            "disabled": _vm.isViewing
          },
          model: {
            value: _vm.reducedPrice,
            callback: function callback($$v) {
              _vm.reducedPrice = $$v;
            },
            expression: "reducedPrice"
          }
        })], 1)], 1)], 1) : _vm._e()]) : _vm._e(), _c('b-row', [_c('b-col', [_c('b-form-group', {
          staticClass: "form-control-label",
          attrs: {
            "description": "The drop hero image is the first image of the drop, it should feature what you're offering."
          }
        }, [(_vm$offer3 = _vm.offer) !== null && _vm$offer3 !== void 0 && _vm$offer3.hero_url ? _c('MediaContainer', {
          attrs: {
            "src": (_vm$offer4 = _vm.offer) === null || _vm$offer4 === void 0 ? void 0 : _vm$offer4.hero_url,
            "disabled": _vm.isViewing,
            "onRemove": _vm.onRemoveMainOfferImage
          }
        }) : _vm._e(), _c('KeepAlive', [_c('ElementUploadValidation', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !((_vm$offer5 = _vm.offer) !== null && _vm$offer5 !== void 0 && _vm$offer5.hero_url),
            expression: "!offer?.hero_url"
          }],
          attrs: {
            "fluidGrow": "",
            "rules": "required",
            "label": "Drop Hero Image",
            "listType": "picture",
            "inputClass": "uploader",
            "accept": "image/png, image/jpeg, image/webp",
            "value": _vm.dropHeroImageUrl,
            "httpRequest": _vm.handleMainOfferImageUpload,
            "showFileList": false,
            "beforeUpload": _vm.beforeImageUpload
          }
        }), _c('div', {
          staticClass: "invalid-feedback",
          staticStyle: {
            "display": "block"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          staticClass: "form-control-label"
        }, [_c('base-input', {
          attrs: {
            "label": "Drop Expiration Date",
            "description": "The expiration date of deal or offer.",
            "disabled": _vm.isViewing,
            "name": "Expiration Date"
          },
          model: {
            value: _vm.dropExpirationDate,
            callback: function callback($$v) {
              _vm.dropExpirationDate = $$v;
            },
            expression: "dropExpirationDate"
          }
        }, [_c('el-date-picker', {
          attrs: {
            "type": "date",
            "format": "MM-dd-yyyy",
            "placeholder": "Select Date",
            "disabled": _vm.isViewing,
            "picker-options": _vm.pickerOptions
          },
          model: {
            value: _vm.dropExpirationDate,
            callback: function callback($$v) {
              _vm.dropExpirationDate = $$v;
            },
            expression: "dropExpirationDate"
          }
        })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('div', {
          staticClass: "my-5"
        }, [_c('h1', {
          staticClass: "display-3 mt-0"
        }, [_vm._v("Additional Drop Details")]), _c('p', {
          staticClass: "lead text-muted"
        }, [_vm._v(" Include supporting images, YouTube or Vimeo videos, as well as drop redemption instructions and requirements. ")])])])], 1)];
      }
    }])
  }), _c('b-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "form-control-label",
    attrs: {
      "description": "Supporting drop images should include pictures relevant to the offer. Users will be able to scroll through these.",
      "label": "Supporting Drop Images"
    }
  }, [(_vm$offer6 = _vm.offer) !== null && _vm$offer6 !== void 0 && _vm$offer6.supporting_image_urls ? _c('div', [_c('b-row', {
    staticClass: "bg-light p-2 rounded",
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l((_vm$offer7 = _vm.offer) === null || _vm$offer7 === void 0 ? void 0 : _vm$offer7.supporting_image_urls, function (image, index) {
    return _c('b-col', {
      key: image,
      staticClass: "p-2"
    }, [_c('b-img', {
      attrs: {
        "thumbnail": "",
        "src": image,
        "alt": image
      }
    })], 1);
  }), 1), _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('base-button', {
    staticClass: "px-0",
    attrs: {
      "type": "link",
      "disabled": _vm.isViewing
    },
    on: {
      "click": _vm.onRemoveImageUrls
    }
  }, [_vm._v(" Remove ")])], 1)], 1) : _c('div', [_c('ElementUploadValidation', {
    attrs: {
      "fluidGrow": "",
      "listType": "picture-card",
      "inputClass": "uploader border-0",
      "value": _vm.dropImageUrls,
      "multiple": true,
      "httpRequest": _vm.handleDropImageUrlsUpload,
      "showFileList": true,
      "beforeUpload": _vm.beforeImageUpload
    }
  })], 1)])], 1)], 1), !_vm.isUrlVideo ? _c('b-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "form-control-label",
    attrs: {
      "description": "Drop video should be promotional video of the offer."
    }
  }, [(_vm$offer8 = _vm.offer) !== null && _vm$offer8 !== void 0 && _vm$offer8.video_url ? _c('MediaContainer', {
    attrs: {
      "src": (_vm$offer9 = _vm.offer) === null || _vm$offer9 === void 0 ? void 0 : _vm$offer9.video_url,
      "video": "",
      "disabled": _vm.isViewing,
      "onRemove": _vm.onRemoveVideo
    }
  }) : _c('ElementUploadValidation', {
    attrs: {
      "inputClass": "uploader",
      "fluidGrow": "",
      "label": "Drop Video Upload",
      "value": _vm.dropVideoUrl,
      "httpRequest": _vm.handleOfferVideoUpload,
      "beforeUpload": _vm.beforeVideoUpload
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [(_vm$offer10 = _vm.offer) !== null && _vm$offer10 !== void 0 && _vm$offer10.video_url && _vm.isUrlVideo ? _c('b-form-group', {
    staticClass: "form-control-label",
    attrs: {
      "label": "Video Preview"
    }
  }, [_c('b-row', [_c('b-col', [_c('URLVideoComponent', {
    attrs: {
      "url": (_vm$offer11 = _vm.offer) === null || _vm$offer11 === void 0 ? void 0 : _vm$offer11.video_url
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('base-button', {
    staticClass: "px-0",
    attrs: {
      "type": "link",
      "disabled": _vm.isViewing
    },
    on: {
      "click": _vm.onRemoveVideo
    }
  }, [_vm._v(" Remove ")])], 1)], 1)], 1) : _vm._e(), !((_vm$offer12 = _vm.offer) !== null && _vm$offer12 !== void 0 && _vm$offer12.video_url) || _vm.isUrlVideo ? _c('b-form-group', {
    staticClass: "form-control-label min-h-100",
    attrs: {
      "label": "YouTube or Vimeo URL",
      "description": "URL of the YouTube or Vimeo video to be embedded in the offer."
    }
  }, [_c('base-input', {
    staticClass: "mb-0",
    attrs: {
      "type": "text",
      "name": "Video URL",
      "rules": "url",
      "disabled": _vm.isViewing,
      "value": (_vm$offer13 = _vm.offer) === null || _vm$offer13 === void 0 ? void 0 : _vm$offer13.video_url
    },
    on: {
      "input": _vm.handleYouTubeVideoInput
    }
  })], 1) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('base-text-area', {
    attrs: {
      "type": "text",
      "rows": 8,
      "label": "Redemption Requirements",
      "description": "Does the user need to be a member of a loyalty program to redeem this drop? Or perhaps make a purchase of equal or lesser value? Describe the requirements.",
      "name": "Redemption Requirements",
      "rules": "min:5|max:2000",
      "disabled": _vm.isViewing
    },
    model: {
      value: _vm.dropRedemptionRequirements,
      callback: function callback($$v) {
        _vm.dropRedemptionRequirements = $$v;
      },
      expression: "dropRedemptionRequirements"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('base-text-area', {
    attrs: {
      "type": "text",
      "rows": 8,
      "label": "Redemption Instructions",
      "name": "Redemption Instructions",
      "rules": "min:5|max:2000",
      "description": "How will the user redeem your drop in store or online? Describe the process in detail.",
      "disabled": _vm.isViewing
    },
    model: {
      value: _vm.dropRedemptionInstructions,
      callback: function callback($$v) {
        _vm.dropRedemptionInstructions = $$v;
      },
      expression: "dropRedemptionInstructions"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('base-text-area', {
    attrs: {
      "type": "text",
      "rows": 8,
      "label": "Legal",
      "description": "Any disclaimer or legal text that you would like to add to to show the user.",
      "name": "Legal",
      "rules": "min:5|max:2000",
      "disabled": _vm.isViewing
    },
    model: {
      value: _vm.dropLegal,
      callback: function callback($$v) {
        _vm.dropLegal = $$v;
      },
      expression: "dropLegal"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }