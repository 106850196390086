// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
// import '../../polyfills'
// Notifications plugin. Used for notifications
import Notifications from '@/components/NotificationsPlugin';
// Validation plugin used to validate forms
import { configure } from 'vee-validate';
// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents';
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives';
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from '@/components/SidebarPlugin';
// element ui language configuration
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import 'element-ui/lib/theme-chalk/index.css';
locale.use(lang);
// vue-bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// vee validate
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';
import moment from 'moment';
// asset imports
import '@/argon/assets/sass/argon.scss';
import '@/argon/assets/css/nucleo/css/nucleo.css';
import '@/assets/styles/global-custom.scss';
//custom date validation
Object.keys(rules).forEach((rule) => {
    extend(rule, {
        ...rules[rule],
        message: messages[rule] // assign message
    });
});
extend('url', {
    validate(value) {
        if (value) {
            // eslint-disable-next-line no-useless-escape
            return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
        }
        return false;
    },
    message: '{_field_} must be a valid URL'
});
extend('youtube', {
    validate(value) {
        if (value) {
            // eslint-disable-next-line no-useless-escape
            return /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/.test(value);
        }
        return false;
    },
    message: '{_field_} must be a valid YouTube url'
});
extend('inFuture', {
    validate(value) {
        console.log('inFuture', value);
        if (moment(value).isBefore(moment.now())) {
            return false;
        }
        else {
            return true;
        }
    },
    message: '{_field_} must be a future Date.'
});
extend('validStart', {
    validate(value) {
        if (moment(value[0]).isAfter(moment.now())) {
            return true;
        }
        else {
            return false;
        }
    },
    message: '{_field_} must be a future Date.'
});
extend('afterStart', {
    params: ['target'],
    validate(value, target) {
        return moment(value).isAfter(moment(target));
    },
    message: '{_field_} must be after Start Time.'
});
/* eslint-disable */
extend('phone', {
    params: ['target'],
    validate(value, target) {
        const phoneRegex = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        return phoneRegex.test(value);
    },
    message: '{_field_} must be valid USA or Canada phone number.'
});
/* eslint-enable */
export default {
    install(Vue) {
        Vue.use(GlobalComponents);
        Vue.use(GlobalDirectives);
        Vue.use(SideBar);
        Vue.use(Notifications);
        Vue.use(BootstrapVue);
        Vue.use(IconsPlugin);
        configure({
            classes: {
                valid: 'is-valid',
                invalid: 'is-invalid',
                dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
            }
        });
    }
};
