import Axios from 'axios';
import { REQUEST_METHOD, PROVIDERS_REQUEST, PROVIDERS_TYPE } from '@gd-mono/gd-common';
import Client, { API_URL } from './Client';
export default class GyftProviderClient {
    static async makePurchase(shopCardId, transactionId) {
        throw new Error('Method not implemented yet');
    }
    static async readMerchant(merchantId) {
        throw new Error('Method not implemented yet');
    }
    static async readAllMerchants() {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + `/providers/${PROVIDERS_TYPE.GYFT}/${PROVIDERS_REQUEST.MERCHANTS}`,
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
    static async readAccountInfo() {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + `/providers/${PROVIDERS_TYPE.GYFT}/${PROVIDERS_REQUEST.ACCOUNT}`,
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
    static async readAllTransactions() {
        throw new Error('Method not implemented yet');
    }
    static async readTransaction(transId) {
        throw new Error('Method not implemented yet');
    }
    static async refundTransaction(transId) {
        throw new Error('Method not implemented yet');
    }
    static async readLatestTransactions(numRecords) {
        throw new Error('Method not implemented yet');
    }
}
