var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "changelog-modal",
      "title": "What's New?",
      "size": "lg",
      "scrollable": "",
      "ok-only": ""
    }
  }, [_c('VueMarkdown', {
    attrs: {
      "source": _vm.changelog
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }