var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-vh-100"
  }, [_c('base-header', {
    staticClass: "pb-6 profile-header"
  }, [_c('span', {
    staticClass: "mask bg-gradient-primary opacity-8"
  }), _c('b-row', {
    staticClass: "py-4",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "7",
      "lg": "6"
    }
  }, [_c('nav', {
    staticClass: "d-none d-md-inline-block",
    attrs: {
      "aria-label": "breadcrumb"
    }
  }, [_c('RouteBreadCrumb')], 1)])], 1), _c('b-row', {
    staticClass: "my-1"
  }, [_c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('h1', {
    staticClass: "display-2 text-white"
  }, [_vm._v("Coming Soon!")])])], 1)], 1), _c('b-container', {
    staticClass: "mt--5"
  }, [_c('b-card', {
    staticClass: "mb-4"
  }, [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "d-flex justify-content-center px-5 py-5"
  }, [_c('Construction', {
    staticClass: "card-svg"
  })], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }