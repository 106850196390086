var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-0 fh-section profile-header"
  }, [_c('Header', {
    staticClass: "hero-header"
  }), _c('b-row', {
    staticClass: "h-100",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "header d-flex align-items-center h-100"
  }, [_c('b-container', {
    staticClass: "hero-container"
  }, [_c('b-row', {
    staticClass: "h-100",
    attrs: {
      "align-v": "center",
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "hero-text",
    attrs: {
      "xl": "6",
      "lg": "7"
    }
  }, [_c('h1', {
    staticClass: "text-dark mb-5 text-giant"
  }, [_c('div', {
    staticClass: "underline-blue"
  }, [_vm._v("Deals")]), _vm._v(" and "), _c('div', {
    staticClass: "underline-yellow"
  }, [_vm._v("gifts")]), _vm._v(" from all around you in one place! ")]), _c('p', {
    staticClass: "lead font-weight-normal"
  }, [_vm._v(" Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard. ")]), _c('img', {
    attrs: {
      "src": require("@/assets/img/app-store-button.png"),
      "width": "220px",
      "alt": "app store button"
    }
  }), _c('img', {
    attrs: {
      "src": require("@/assets/img/google-play-badge.png"),
      "width": "255px",
      "alt": "google play badge"
    }
  })]), _c('b-col', {
    staticClass: "justify-content-center align-items-center my-5 d-none d-lg-block text-center",
    attrs: {
      "xl": "6",
      "lg": "5"
    }
  }, [_c('img', {
    staticClass: "img-fluid landing-screen",
    staticStyle: {
      "object-fit": "contain"
    },
    attrs: {
      "src": require("@/assets/img/PhoneHeroImage2.png"),
      "alt": "giftdrop preview"
    }
  })])], 1)], 1)], 1)])], 1), _c('b-row', {
    staticClass: "fh-section whats-giftdrop position-relative",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('img', {
    staticClass: "arrow",
    attrs: {
      "src": require("@/assets/img/arrow.png"),
      "alt": "",
      "width": "150px"
    }
  }), _c('b-col', {
    staticClass: "h-100"
  }, [_c('WhatsGiftdrop')], 1)], 1), _c('b-row', {
    staticClass: "download-the-app position-relative",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "h-100"
  }, [_c('DownloadTheApp')], 1)], 1), _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', [_c('ContentFooter')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }