var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-vh-100"
  }, [_c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', [_c('base-header', {
    staticClass: "pb-6 profile-header"
  }, [_c('span', {
    staticClass: "mask bg-gradient-info opacity-8"
  }), _c('b-row', {
    staticClass: "py-4",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "7",
      "lg": "6"
    }
  }, [_c('nav', {
    staticClass: "d-none d-md-inline-block",
    attrs: {
      "aria-label": "breadcrumb"
    }
  }, [_c('RouteBreadCrumb')], 1)])], 1), _c('b-row', {
    staticClass: "my-1"
  }, [_c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('h1', {
    staticClass: "display-2 text-white"
  }, [_vm._v("Gyft Panel")])])], 1)], 1)], 1)], 1), _c('b-container', [_c('b-row', {
    staticClass: "mt--6",
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.isAdmin ? _c('b-col', [_c('b-card', {
    attrs: {
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-v": "center"
          }
        }, [_c('b-col', [_c('h3', {
          staticClass: "mb-0"
        }, [_vm._v("My Gyft Account")])])], 1), _vm._l(_vm.gyftAccount, function (value, key) {
          return _c('b-row', {
            key: key,
            staticClass: "justify-content-center"
          }, [_c('b-col', {
            staticClass: "text-capitalize text-right",
            attrs: {
              "sm": "4"
            }
          }, [_c('h3', [_vm._v(_vm._s(_vm._f("normalizeText")(key)))])]), key !== 'balance_updated_when' ? _c('b-col', {
            staticClass: "text-center",
            staticStyle: {
              "min-width": "250px"
            },
            attrs: {
              "sm": "8"
            }
          }, [_vm._v(" " + _vm._s(value) + " ")]) : _c('b-col', {
            staticClass: "text-center",
            staticStyle: {
              "min-width": "250px"
            },
            attrs: {
              "sm": "8"
            }
          }, [_vm._v(" " + _vm._s(_vm._f("numberToLocaleDateString")(value)) + " ")])], 1);
        })];
      },
      proxy: true
    }], null, false, 2552960627)
  })], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }