import Axios from 'axios';
import { REQUEST_METHOD, PAYMENTS_PATCH_ACTION } from '@gd-mono/gd-common';
import Client, { API_URL } from './Client';
/**
 * @description Handles API request with the payments endpoint to manage payments and stripe customer creation.
 * @author Logan Hendershot
 * @date 08/01/2022
 * @export
 * @class PaymentClient
 * @implements {PaymentInterface}
 */
export class PaymentClient {
    async create(token, targetUserId) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.POST,
            url: API_URL + `/payments/${targetUserId}`,
            headers: {
                authToken
            },
            data: {
                token
            }
        });
        return resp.data.data;
    }
    async read(targetUserId) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + `/payments/${targetUserId}`,
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
    async createCard(token, targetUserId) {
        const payload = {
            action: PAYMENTS_PATCH_ACTION.ADD_CARD,
            token
        };
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.PATCH,
            url: API_URL + `/payments/${targetUserId}`,
            headers: {
                authToken
            },
            data: {
                ...payload
            }
        });
        return resp.data.data;
    }
    async deleteCard(cardId, targetUserId) {
        const payload = {
            action: PAYMENTS_PATCH_ACTION.REMOVE_CARD,
            cardId
        };
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.PATCH,
            url: API_URL + `/payments/${targetUserId}`,
            headers: {
                authToken
            },
            data: {
                ...payload
            }
        });
        return resp.data.data;
    }
    async updateDefaultCard(cardId, targetUserId) {
        const payload = {
            action: PAYMENTS_PATCH_ACTION.UPDATE_DEFAULT,
            cardId
        };
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.PATCH,
            url: API_URL + `/payments/${targetUserId}`,
            headers: {
                authToken
            },
            data: {
                ...payload
            }
        });
        return resp.data.data;
    }
}
