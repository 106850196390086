var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    staticClass: "p-0 login-page profile-header",
    attrs: {
      "fluid": ""
    }
  }, [_c('LandingHeader', {
    staticClass: "position-static landing-header"
  }), _c('b-container', [_c('b-row', {
    staticClass: "vh-100"
  }, [_c('b-col', {
    staticClass: "mt-2 my-auto mx-auto",
    attrs: {
      "md": "5"
    }
  }, [_c('b-card', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "border-0 mx-auto"
  }, [_c('b-card-body', [_c('b-alert', {
    attrs: {
      "variant": "warning",
      "show": _vm.errorState
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.errorState = $event;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]), _c('div', {
    staticClass: "navbar-logo-container w-100"
  }, [_c('img', {
    staticClass: "align-top navbar-logo",
    attrs: {
      "src": require("@/assets/img/LogoWithText.png")
    }
  })]), _c('b-card-title', {
    staticClass: "d-flex justify-content-center"
  }, [_vm._v(" Sign in to your account ")]), _c('div', {
    staticClass: "d-flex flex-row justify-content-center align-items-center text-muted my-2"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_vm._v("Or")]), _c('b-link', {
    attrs: {
      "to": "/create"
    }
  }, [_vm._v("Create Account")])], 1), _c('div', {
    staticClass: "mt-3"
  }, [_c('b-form-group', {
    attrs: {
      "id": "email-group",
      "label": "Email",
      "label-for": "email-input"
    }
  }, [_c('b-form-input', {
    staticClass: "my-2",
    attrs: {
      "id": "email-input",
      "type": "email",
      "required": ""
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.logIn.apply(null, arguments);
      }
    },
    model: {
      value: _vm.emailAddress,
      callback: function callback($$v) {
        _vm.emailAddress = $$v;
      },
      expression: "emailAddress"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "id": "password-group",
      "label": "Password",
      "label-for": "pass-input"
    }
  }, [_c('b-form-input', {
    staticClass: "my-2",
    attrs: {
      "id": "pass-input",
      "type": "password",
      "required": ""
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.logIn.apply(null, arguments);
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('div', {
    staticClass: "my-3 d-flex justify-content-end"
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.forgot-pass-modal",
      modifiers: {
        "forgot-pass-modal": true
      }
    }],
    attrs: {
      "variant": "link"
    }
  }, [_vm._v(" Forgot your password? ")])], 1), _c('div', {
    staticClass: "mx-auto"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "block": "",
      "type": "submit"
    },
    on: {
      "click": _vm.logIn
    }
  }, [_vm._v(" Sign in ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('ContentFooter'), _c('ForgotPasswordModal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }