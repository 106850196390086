var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidationProvider', {
    attrs: {
      "name": _vm.label,
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('label', {
          staticClass: "form-control-label"
        }, [_vm._v(_vm._s(_vm.label))]), _c('el-upload', {
          class: "".concat(_vm.inputClass + ' ' + ' rounded' + '', "}"),
          attrs: {
            "error": errors[0],
            "label": _vm.label,
            "accept": _vm.accept,
            "action": "",
            "multiple": _vm.multiple,
            "fluid-grow": _vm.fluidGrow,
            "list-type": _vm.listType,
            "http-request": _vm.httpRequest,
            "show-file-list": _vm.showFileList,
            "on-remove": _vm.onRemove,
            "before-upload": _vm.beforeUpload
          },
          model: {
            value: _vm.innerValue,
            callback: function callback($$v) {
              _vm.innerValue = $$v;
            },
            expression: "innerValue"
          }
        }, [_c('BIconCloudUpload', {
          staticClass: "el-icon-plus uploader-icon",
          attrs: {
            "scale": 0.3
          }
        })], 1), _c('div', {
          staticClass: "invalid-feedback",
          staticStyle: {
            "display": "block"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }