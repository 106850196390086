var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [_vm.showLabel ? _c('div', {
    class: "progress-".concat(_vm.type)
  }, [_c('div', {
    staticClass: "progress-label"
  }, [_vm._t("label", function () {
    return [_c('span', [_vm._v(_vm._s(_vm.label))])];
  })], 2), _c('div', {
    staticClass: "progress-percentage"
  }, [_vm._t("default", function () {
    return [_c('span', [_vm._v(_vm._s(_vm.value) + "%")])];
  })], 2)]) : _vm._e(), _c('b-progress', {
    class: [_vm.progressClasses],
    style: "height: ".concat(_vm.height, "px"),
    attrs: {
      "size": _vm.size
    }
  }, [_c('b-progress-bar', {
    class: _vm.computedClasses,
    attrs: {
      "value": _vm.value
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }