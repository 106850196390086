var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "bg-gradient-primary gift-card shadow",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-row', {
    attrs: {
      "align-v": "center",
      "align-h": "center"
    }
  }, [_c('b-col', {
    staticClass: "d-flex"
  }, [_c('b-avatar', {
    attrs: {
      "src": _vm.logoUrl,
      "alt": "Company Image",
      "size": "5rem",
      "rounded": "lg"
    }
  }), _c('h1', {
    staticClass: "text-white align-self-bottom mt-auto ml-3"
  }, [_vm._v(_vm._s(_vm.companyName))])], 1), _c('b-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('span', {
    staticClass: "h6 surtitle text-light"
  }, [_vm._v("Value")]), _c('span', {
    staticClass: "d-block h1 text-white"
  }, [_vm._v(_vm._s(_vm.value))])])], 1), _c('div', {
    staticClass: "my-4"
  }, [_c('span', {
    staticClass: "h6 surtitle text-light"
  }, [_vm._v(" Card number ")]), _c('div', {
    staticClass: "card-serial-number h1 text-white d-flex justify-start"
  }, _vm._l(_vm.number.match(/.{1,4}/g), function (part, index) {
    return _c('div', {
      key: index
    }, [_vm._v(" " + _vm._s(part) + " ")]);
  }), 0)]), _c('b-row', [_c('b-col', [_c('span', {
    staticClass: "h6 surtitle text-light"
  }, [_vm._v("Name")]), _c('span', {
    staticClass: "d-block h3 text-white"
  }, [_vm._v("A " + _vm._s(_vm.companyName ? _vm.companyName : 'New') + " Gift Card")])]), _c('b-col', [_c('span', {
    staticClass: "h6 surtitle text-light"
  }, [_vm._v("Expiry date")]), _c('span', {
    staticClass: "d-block h3 text-white"
  }, [_vm._v(_vm._s(_vm.expire))])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }