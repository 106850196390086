var render = function render(){
  var _vm$company, _vm$company2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card-body', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "pt-0"
  }, [_c('b-card-title', [_vm._v("Company Locations")]), _c('b-card-text', [_vm._v(" You can add your company locations here that you can require users to be near to redeem. ")]), _c('b-form-group', {
    staticClass: "form-control-label mb-0",
    attrs: {
      "disabled": _vm.viewing,
      "label": "Add Locations"
    }
  }, [_c('base-switch', {
    attrs: {
      "on-text": "Yes",
      "off-text": "No"
    },
    on: {
      "input": _vm.locationSwitch
    },
    model: {
      value: _vm.addLocations,
      callback: function callback($$v) {
        _vm.addLocations = $$v;
      },
      expression: "addLocations"
    }
  })], 1)], 1), _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_c('b-card', {
    staticClass: "shadow-none mb-0",
    attrs: {
      "no-body": ""
    }
  }, [_vm.addLocations ? _c('b-row', [_c('b-col', [_c('GmapMap', {
    ref: "mapRef",
    staticClass: "w-100",
    staticStyle: {
      "height": "500px"
    },
    attrs: {
      "center": _vm.mapCenter.position,
      "zoom": _vm.mapCenter.zoom
    }
  }, _vm._l((_vm$company = _vm.company) === null || _vm$company === void 0 ? void 0 : _vm$company.locations, function (location, index) {
    return _c('GmapMarker', {
      key: index,
      attrs: {
        "position": {
          lat: location.location.lat,
          lng: location.location.lng
        }
      }
    });
  }), 1)], 1)], 1) : _vm._e(), _c('b-card', {
    staticClass: "shadow-none mb-0",
    attrs: {
      "no-body": ""
    }
  }, [_vm.editing || _vm.creating ? _c('b-row', [_c('b-col', {
    staticClass: "mx-4"
  }, [_c('GmapAutocomplete', {
    ref: "autoComplete",
    staticClass: "input-group-lg form-control my-3",
    attrs: {
      "value": _vm.value,
      "placeholder": "Search for a store location to add"
    },
    on: {
      "place_changed": _vm.setPlace
    }
  })], 1)], 1) : _vm._e(), _vm.addLocations ? _c('h5', {
    staticClass: "h3 my-3 mx-4"
  }, [_vm._v("Added Locations")]) : _vm._e(), _c('b-list-group', {
    attrs: {
      "flush": "",
      "data-toggle": "checklist"
    }
  }, _vm._l((_vm$company2 = _vm.company) === null || _vm$company2 === void 0 ? void 0 : _vm$company2.locations, function (location, index) {
    return _c('b-list-group-item', {
      key: index,
      staticClass: "checklist-entry flex-column align-items-start py-4 px-4"
    }, [_c('div', {
      staticClass: "checklist-item"
    }, [_c('div', {
      staticClass: "checklist-info"
    }, [_c('h5', {
      staticClass: "checklist-title mb-0"
    }, [_vm._v(" " + _vm._s(location.name) + " ")]), _c('small', [_vm._v(_vm._s(location.address))])]), _vm.editing ? _c('div', {
      staticClass: "col-2 d-flex align-items-center",
      staticStyle: {
        "cursor": "pointer"
      }
    }, [_c('div', {
      staticClass: "trash-icon",
      on: {
        "click": function click($event) {
          return _vm.dropLocation(index);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "name": "trash"
      }
    })], 1)]) : _vm._e()])]);
  }), 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }