var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BaseHeader', {
    staticClass: "pb-6 profile-header"
  }, [_c('span', {
    staticClass: "mask bg-gradient-info opacity-8"
  }), _c('b-row', {
    staticClass: "py-4",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "7",
      "lg": "6"
    }
  }, [_c('nav', {
    staticClass: "d-none d-md-inline-block",
    attrs: {
      "aria-label": "breadcrumb"
    }
  }, [_c('RouteBreadCrumb')], 1)])], 1), _c('b-row', {
    staticClass: "my-1"
  }, [_c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('h1', {
    staticClass: "display-2 text-white"
  }, [_vm._v("Gratitudes Management")])])], 1)], 1), _c('b-container', {
    staticClass: "mt--5"
  }, [_c('b-card', [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "title": "Images"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-4"
  }, [_c('h2', [_vm._v("Create a Thank You Image")])])], 1), _c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "3"
    }
  }, [_c('div', [_c('input', {
    ref: "file",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.uploadFile
    }
  }), _c('div', {
    directives: [{
      name: "b-hover",
      rawName: "v-b-hover",
      value: _vm.handleHover,
      expression: "handleHover"
    }],
    staticClass: "text-center"
  }, [_vm.isHovered ? _c('b-avatar', {
    attrs: {
      "button": "",
      "icon": "upload",
      "size": "8rem"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.file.click();
      }
    }
  }) : _vm.newGratImageUrl ? _c('b-avatar', {
    attrs: {
      "src": _vm.newGratImageUrl,
      "size": "8rem"
    }
  }) : _c('b-avatar', {
    attrs: {
      "icon": "image",
      "size": "8rem"
    }
  })], 1)]), _c('p', {
    staticClass: "text-muted"
  }, [_vm._v("*Image must be square.")])])], 1), _c('div', {
    staticClass: "text-right mt-2"
  }, [_c('b-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.newGratImageUrl,
      expression: "newGratImageUrl"
    }],
    staticClass: "my-2",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.removeAvatarImage();
      }
    }
  }, [_vm._v(" Cancel ")]), _c('b-button', {
    staticClass: "my-3",
    attrs: {
      "disabled": !_vm.newGratImageUrl,
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.createNewGratitude('IMAGE', _vm.newGratImageUrl);
      }
    }
  }, [_vm._v(" Create ")])], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-5 mx-3",
    staticStyle: {
      "border-bottom": "2px solid #d3d3d3"
    }
  })], 1), _c('b-row', [_c('b-col', [_c('h2', [_vm._v("Images In Rotation: " + _vm._s(_vm.activeImages.length))]), _c('div', {
    staticClass: "d-flex flex-row flex-start flex-wrap my-3"
  }, _vm._l(_vm.activeImages, function (grat) {
    return _c('div', {
      key: grat.data,
      staticClass: "d-flex flex-column justify-content-center align-items-center mx-3 my-3"
    }, [_c('b-avatar', {
      attrs: {
        "src": grat.data,
        "size": "8rem"
      }
    }), _c('a', {
      staticClass: "text-underline action my-2",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.archive(grat);
        }
      }
    }, [_vm._v(" Archive ")]), _c('a', {
      staticClass: "text-underline action",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.remove(grat.id);
        }
      }
    }, [_vm._v(" Remove ")])], 1);
  }), 0)])], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-5 mx-3",
    staticStyle: {
      "border-bottom": "2px solid #d3d3d3"
    }
  })], 1), _c('b-row', [_c('b-col', [_c('h2', [_vm._v("Archived")]), _c('div', {
    staticClass: "d-flex flex-row flex-start flex-wrap my-3"
  }, _vm._l(_vm.inactiveImages, function (grat) {
    return _c('div', {
      key: grat.data,
      staticClass: "d-flex flex-column justify-content-center align-items-center mx-3 my-3"
    }, [_c('b-avatar', {
      attrs: {
        "src": grat.data,
        "size": "8rem"
      }
    }), _c('a', {
      staticClass: "text-underline action my-2",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.restore(grat);
        }
      }
    }, [_vm._v(" Restore ")]), _c('a', {
      staticClass: "text-underline action",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.remove(grat.id);
        }
      }
    }, [_vm._v(" Remove ")])], 1);
  }), 0)])], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Messages"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-4"
  }, [_c('h2', [_vm._v("Create a Thank You message")])])], 1), _c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Thank You message"
    },
    model: {
      value: _vm.newGratText,
      callback: function callback($$v) {
        _vm.newGratText = $$v;
      },
      expression: "newGratText"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "text-right"
  }, [_c('b-button', {
    staticClass: "my-3",
    attrs: {
      "disabled": !_vm.newGratText,
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.createNewGratitude('TEXT', _vm.newGratText);
      }
    }
  }, [_vm._v(" Create ")])], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-5 mx-3",
    staticStyle: {
      "border-bottom": "2px solid #d3d3d3"
    }
  })], 1), _c('b-row', [_c('b-col', [_c('h2', [_vm._v("Text In Rotation: " + _vm._s(_vm.activeText.length))]), _c('div', {
    staticClass: "d-flex flex-row flex-start flex-wrap my-3"
  }, _vm._l(_vm.activeText, function (grat) {
    return _c('div', {
      key: grat.data,
      staticClass: "d-flex flex-column justify-content-center align-items-center mx-3 my-3"
    }, [_c('b-card', [_c('h4', {
      staticClass: "my-1"
    }, [_vm._v("\"" + _vm._s(grat.data) + "\"")])]), _c('a', {
      staticClass: "text-underline action my-2",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.archive(grat);
        }
      }
    }, [_vm._v(" Archive ")]), _c('a', {
      staticClass: "text-underline action",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.remove(grat.id);
        }
      }
    }, [_vm._v(" Remove ")])], 1);
  }), 0)])], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-5 mx-3",
    staticStyle: {
      "border-bottom": "2px solid #d3d3d3"
    }
  })], 1), _c('b-row', [_c('b-col', [_c('h2', [_vm._v("Archived")]), _c('div', {
    staticClass: "d-flex flex-row flex-start flex-wrap my-3"
  }, _vm._l(_vm.inactiveText, function (grat) {
    return _c('div', {
      key: grat.data,
      staticClass: "d-flex flex-column justify-content-center align-items-center mx-3 my-3"
    }, [_c('b-card', [_c('h4', {
      staticClass: "my-1"
    }, [_vm._v("\"" + _vm._s(grat.data) + "\"")])]), _c('a', {
      staticClass: "text-underline action my-2",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.restore(grat);
        }
      }
    }, [_vm._v(" Restore ")]), _c('a', {
      staticClass: "text-underline action",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.remove(grat.id);
        }
      }
    }, [_vm._v(" Remove ")])], 1);
  }), 0)])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-5 mx-3",
    staticStyle: {
      "border-bottom": "2px solid #d3d3d3"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }