var render = function render(){
  var _vm$targetOffer, _vm$targetOffer2, _vm$targetOffer3, _vm$targetOffer4, _vm$targetOffer5, _vm$offerStore, _vm$targetOffer6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidationProvider', {
    ref: "provider"
  }, [_c('b-row', {
    staticClass: "p-3",
    attrs: {
      "id": "offers-container"
    }
  }, [_c('b-col', [_vm.progress === 0 ? _c('b-card-group', {
    attrs: {
      "deck": ""
    }
  }, [_c('div', {
    staticClass: "mb-5"
  }, [_c('h1', {
    staticClass: "display-3 mt-0"
  }, [_vm._v("What do you want to drop on the map?")]), _c('p', {
    staticClass: "lead text-muted"
  }, [_vm._v(" From free giveaways and discounts to Gift Cards, create your offer then drop it on the map for Gift Drop users to pick up and redeem. ")])]), _c('OffersCardComponent', {
    attrs: {
      "title": "Discount, Sale or Free Item",
      "description": "This drop will be a discount on a product or service, either a whole purchase or a specific item. 20% off a Latte or 10% off total purchase. A buy one get one or BOGO offer.",
      "example": "20% Off All Purchases",
      "imgSrc": "https://via.placeholder.com/300x200?Text=Placeholder",
      "selected": _vm.selectedItem === null ? null : _vm.selectedItem === 'DISCOUNT'
    },
    on: {
      "onSelect": function onSelect($event) {
        return _vm.offerTypeChange('DISCOUNT');
      }
    }
  }), _c('OffersCardComponent', {
    attrs: {
      "title": "Gift Card",
      "description": "This drop will allow users to pick up and redeem your business Gift\n                            Cards from the map. You can upload your inventory or select from our\n                            catalog.",
      "example": "a giveaway of 50, $5 Gift Cards to your business",
      "imgSrc": "https://via.placeholder.com/300x200?Text=Placeholder",
      "selected": _vm.selectedItem === null ? null : _vm.selectedItem === 'GIFTCARD'
    },
    on: {
      "onSelect": function onSelect($event) {
        return _vm.offerTypeChange('GIFTCARD');
      }
    }
  })], 1) : _vm._e(), _c('SlideYUpTransition', {
    attrs: {
      "group": ""
    }
  }, [((_vm$targetOffer = _vm.targetOffer) === null || _vm$targetOffer === void 0 ? void 0 : _vm$targetOffer.type) === 'DISCOUNT' ? _c('div', {
    key: 1
  }, [_c('DiscountTypeSelect', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.progress === 0,
      expression: "progress === 0"
    }]
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ((_vm$targetOffer2 = _vm.targetOffer) === null || _vm$targetOffer2 === void 0 ? void 0 : _vm$targetOffer2.sub_type) && _vm.progress === 0,
      expression: "targetOffer?.sub_type && progress === 0"
    }],
    key: 8
  }, [_c('DropSourceTypeSelect', {
    on: {
      "offerSourceChange": _vm.offerSourceChange,
      "next": _vm.next
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ((_vm$targetOffer3 = _vm.targetOffer) === null || _vm$targetOffer3 === void 0 ? void 0 : _vm$targetOffer3.inventory_type) && _vm.progress === 1,
      expression: "targetOffer?.inventory_type && progress === 1"
    }],
    key: 6
  }, [_c('OfferTypePreview', {
    attrs: {
      "targetOffer": _vm.targetOffer
    }
  }), _c('DropDescription')], 1)], 1) : _vm._e(), ((_vm$targetOffer4 = _vm.targetOffer) === null || _vm$targetOffer4 === void 0 ? void 0 : _vm$targetOffer4.type) === 'GIFTCARD' ? _c('div', {
    key: 7
  }, [_c('GiftCardSourceSelect', {
    attrs: {
      "selectedSource": _vm.selectedSource
    },
    on: {
      "offerSourceChange": _vm.offerSourceChange
    }
  }), ((_vm$targetOffer5 = _vm.targetOffer) === null || _vm$targetOffer5 === void 0 ? void 0 : _vm$targetOffer5.inventory_type) === 'PROVIDER' ? _c('b-col', [_c('div', {
    staticClass: "mt-5"
  }, [_c('SelectProviderTable'), ((_vm$offerStore = _vm.offerStore) === null || _vm$offerStore === void 0 ? void 0 : _vm$offerStore.getters.targetProvider) !== undefined ? _c('div', [_c('InventoryUploadAlert'), _c('DropDescription')], 1) : _vm._e()], 1)]) : _vm._e(), ((_vm$targetOffer6 = _vm.targetOffer) === null || _vm$targetOffer6 === void 0 ? void 0 : _vm$targetOffer6.inventory_type) === 'INVENTORY' ? _c('b-col', [_c('InventoryUploadAlert', {
    attrs: {
      "show": true
    }
  }), _c('DropDescription')], 1) : _vm._e()], 1) : _vm._e()])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }