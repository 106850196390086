import Axios from 'axios';
import ENDPOINTS from '../util/endpoints';
import firebase from 'firebase/app';
import 'firebase/auth';
import { REQUEST_METHOD } from '@gd-mono/gd-common';
export const API_URL = process.env.VUE_APP_BASE_API;
export default class Client {
    static async getAuthToken() {
        return await this.getCurrentUser().then(async (user) => {
            const token = await user.getIdToken();
            return token;
        });
    }
    /**
     * @description Returns a promise that is mainly used in main.ts to monitor if the requested path requires auth or not.
     * @author Logan Hendershot
     * @date 11/10/2021
     * @return {*}  {Promise<firebase.User>}
     * @memberof Client
     */
    static getCurrentUser() {
        return new Promise((resolve, reject) => {
            const unsubscribe = firebase.auth().onIdTokenChanged((user) => {
                unsubscribe();
                resolve(user);
            }, reject);
        });
    }
    // TODO refactort this out
    /**
     * @description Assigns the gift cards ids listed to the targetId, being either a campaign or catalog merchant
     * @author Logan Hendershot
     * @date 11/10/2021
     * @param {string} targetId
     * @param {string[]} giftCardIds
     * @returns {*}  {Promise<unknown>}
     * @memberof Client
     */
    // static async assignGiftCards(targetId: string, giftCardIds: string[]): Promise<unknown> {
    //     const authToken = await this.getAuthToken()
    //     const resp = await Axios({
    //         method: REQUEST_METHOD.POST,
    //         url: API_URL + ENDPOINTS.CAMPAIGNS + '/' + targetId + '/giftcards/add',
    //         headers: {
    //             authToken
    //         },
    //         data: {
    //             cardIds: giftCardIds
    //         }
    //     })
    //     return resp.data.data
    // }
    /**
     * @description Returns all the gift card merchants including our gyft catalog, the filtered IDs and
     * and the catalog merchants that have cards in stock.
     * @author Logan Hendershot
     * @date 11/10/2021
     * @returns {*}  {Promise<GetGiftCardMerchantsResponse>}
     * @memberof Client
     */
    static async getGiftCardMerchants() {
        const authToken = await this.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + ENDPOINTS.GIFTCARDS + '/merchants/all',
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
    /**
     * @description Purchases a Gyft giftcard, doesn't include a token though so I'm not sure it works, probably
     * also only callable by an admin
     * @author Logan Hendershot
     * @date 11/10/2021
     * @param {string} merchantId
     * @param {number} shopCardId
     * @returns {*}  {Promise<GyftPurchaseResponse>}
     * @memberof Client
     */
    static async purchaseAndCreateGyftGiftCard(merchantId, shopCardId) {
        const authToken = await this.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.POST,
            url: API_URL + ENDPOINTS.GIFTCARDS + '/merchants/gyft/purchase',
            data: {
                merchantId: merchantId,
                shopCardId: shopCardId
            },
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
    /**
     * @description Updates the gyft merchant filter to the given string array. This call performs a replace.
     * @author Logan Hendershot
     * @date 11/10/2021
     * @param {string[]} filterIds
     * @returns {*}  {Promise<string[]>}
     * @memberof Client
     */
    static async updateGyftMerchantFilter(filterIds) {
        const authToken = await this.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.PUT,
            url: API_URL + ENDPOINTS.GIFTCARDS + '/merchants/gyft/filter',
            data: {
                idsToFilter: filterIds
            },
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
    /** Catalog Merchants */
    static async getCatalogMerchants() {
        const authToken = await this.getAuthToken();
        const response = await Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + ENDPOINTS.CATALOG_MERCHANTS,
            headers: {
                authToken
            }
        });
        const merchantCatalog = response.data.data;
        return merchantCatalog;
    }
    static async createCatalogMerchant(catalogMerchant) {
        const authToken = await this.getAuthToken();
        await Axios({
            method: REQUEST_METHOD.POST,
            data: catalogMerchant,
            url: API_URL + ENDPOINTS.CATALOG_MERCHANTS,
            headers: {
                authToken
            }
        });
    }
    static async pauseCatalogMerchant(catalogMerchantId, pauseVal) {
        const authToken = await this.getAuthToken();
        await Axios({
            url: API_URL + `${ENDPOINTS.CATALOG_MERCHANTS}/${catalogMerchantId}/pause`,
            method: REQUEST_METHOD.POST,
            data: {
                pause: pauseVal
            },
            headers: {
                authToken
            }
        });
    }
    static async updateCatalogMerchant(catalogMerchant) {
        const authToken = await this.getAuthToken();
        await Axios({
            url: API_URL + `${ENDPOINTS.CATALOG_MERCHANTS}/${catalogMerchant.id}`,
            method: REQUEST_METHOD.PUT,
            data: catalogMerchant,
            headers: {
                authToken
            }
        });
    }
    // referral analytics
    static async referralAnalytics(referralId) {
        const authToken = await this.getAuthToken();
        const type = 'LINK_VISITED';
        await Axios({
            url: API_URL + `${ENDPOINTS.REFERRAL}/${referralId}/analytics`,
            method: REQUEST_METHOD.POST,
            data: {
                type: type
            },
            headers: {
                authToken
            }
        });
    }
    static async uploadImage(file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('tags', 'browser_upload');
        formData.append('upload_preset', process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET);
        const url = `https://api.cloudinary.com/v1_1/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/upload`;
        const config = {
            headers: { 'X-Requested-With': 'XMLHttpRequest' }
        };
        return await Axios.post(url, formData, config);
    }
    /// //////////////////////////////////////////
    // async getCardMerchants() {
    //     const response = await Axios({
    //         method: REQ_METHOD.GET,
    //         url: API_URL + '/giftcard/merchants',
    //         headers: {
    //             authToken
    //         }
    //     })
    //     return response
    // }
    static async getSiteMetrics(since) {
        const authToken = await this.getAuthToken();
        const response = await Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + '/site/metrics?since=' + since,
            headers: {
                authToken
            }
        });
        // console.log('*** RESPONSE ***', response)
        return response;
    }
}
