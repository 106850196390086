import Axios from 'axios';
import { REQUEST_METHOD } from '@gd-mono/gd-common';
import Client, { API_URL } from './Client';
export class TransactionClient {
    read(id, callingUser) {
        throw new Error('Method not implemented.');
    }
    async readAll() {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + `/transactions`,
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
    async readByUserId(targetUserId, callingUser) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + `/transactions?userId=${targetUserId}`,
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
}
