import { REQUEST_METHOD } from "@gd-mono/gd-common";
import Axios from "axios";
import Client, { API_URL } from "./Client";
export class UserClientImpl {
    static instance;
    // private constructor() {}
    static getInstance() {
        if (!UserClientImpl.instance) {
            UserClientImpl.instance = new UserClientImpl();
        }
        return UserClientImpl.instance;
    }
    async create(data) {
        const authToken = await Client.getAuthToken();
        // create user in database via api
        const resp = await Axios({
            method: REQUEST_METHOD.POST,
            url: `${API_URL}/users`,
            headers: {
                authToken,
            },
            data: {
                ...data,
            },
        });
        return resp.data.data;
    }
    async read(uid) {
        const authToken = await Client.getAuthToken();
        const response = await Axios({
            method: REQUEST_METHOD.GET,
            url: `${`${API_URL}/users/${uid}`}`,
            headers: {
                authToken,
            },
        });
        return response.data.data;
    }
    async readAll() {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: `${API_URL}/users`,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
    async update(user) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.PUT,
            url: `${API_URL}/users/${user.id}`,
            headers: {
                authToken,
            },
            data: {
                ...user,
            },
        });
        return resp.data.data;
    }
    async delete(id) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.DELETE,
            url: `${API_URL}/users/${id}`,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
    async block(id, block) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.PUT,
            url: `${API_URL}/users/${id}/block`,
            headers: {
                authToken,
            },
            data: {
                block,
            },
        });
        return resp.data.data;
    }
    async approve(id, approve) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.PUT,
            url: `${API_URL}/users/${id}/approve`,
            headers: {
                authToken,
            },
            data: {
                approve,
            },
        });
        return resp.data.data;
    }
}
