<template>
    <card class="card-stats" :show-footer-line="true">
        <b-row>
            <b-col style="min-width: 150px">
                <slot>
                    <h5 class="card-title text-uppercase text-muted mb-0" v-if="title">
                        {{ title }}
                    </h5>
                    <span class="h2 font-weight-bold mb-0" v-if="subTitle">{{ subTitle }}</span>
                </slot>
            </b-col>

            <b-col cols="auto" v-if="$slots.icon || icon">
                <slot name="icon">
                    <div
                        class="icon icon-shape text-white rounded-circle shadow"
                        :class="[`bg-${type}`, iconClasses]">
                        <b-icon :icon="icon"></b-icon>
                    </div>
                </slot>
            </b-col>
        </b-row>

        <p class="mt-3 mb-0 text-sm">
            <slot name="footer"> </slot>
        </p>
    </card>
</template>
<script>
import Card from './Card.vue'
import { BIcon } from 'bootstrap-vue'

export default {
    name: 'stats-card',
    components: {
        Card,
        BIcon
    },
    props: {
        type: {
            type: String,
            default: 'primary'
        },
        icon: String, // Some bootstrap-vue icon name. See https://bootstrap-vue.org/docs/icons
        title: String,
        subTitle: String,
        iconClasses: [String, Array]
    }
}
</script>
