<template>
    <bread-crumb list-classes="breadcrumb-links breadcrumb-dark">
        <BreadCrumbItem class="d-flex align-items-center">
            <router-link :to="{ name: 'Dashboard' }">
                <BIconHouseFill height="22px" width="22px" />
            </router-link>
        </BreadCrumbItem>
        <BreadCrumbItem
            v-for="(route, index) in filteredRoutes"
            :key="route.name"
            :active="index === $route.matched.length - 1"
            style="display: inline-block; font-size: 20px">
            <router-link :to="{ name: route.name }" v-if="index < $route.matched.length - 1">
                {{ route.name }}
            </router-link>
            <span v-else>{{ route.name }}</span>
        </BreadCrumbItem>
    </bread-crumb>
</template>

<script>
import BreadCrumb from './Breadcrumb'
import BreadCrumbItem from './BreadcrumbItem'
import { BIconHouseFill } from 'bootstrap-vue'

export default {
    name: 'route-breadcrumb',
    components: {
        BreadCrumb,
        BreadCrumbItem,
        BIconHouseFill
    },
    computed: {
        // used to not show layout routes
        filteredRoutes() {
            return this.$route.matched.filter((route) => {
                return !route.meta.isLayout
            })
        }
    }
}
</script>

<style scoped></style>
