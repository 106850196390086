/* eslint-disable */
import { storeBuilder } from '@/store/RootState';
import GyftProviderClient from '../../service/GyftProviderClient';
import * as notificationStore from './NotificationStore';
/**
 * @description Base store state for handling Gyft Provicer Data
 * @author Alejandro Nieto
 * @date 08/30/2022
 * @export
 * @class GyftState
 */
export class GyftState {
    loading = false;
    account = null;
}
// Gyft module builder
export const GyftModule = storeBuilder.module('Gyft', new GyftState());
// Getters
const getterFunctions = {
    getAccount: GyftModule.read((state) => state.account, 'getAccount')
};
export const getters = {
    get getAccount() {
        return getterFunctions.getAccount();
    }
};
async function getAccount(context) {
    try {
        const account = await GyftProviderClient.readAccountInfo();
        mutations.setAccount(account);
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: 'Error getting Gyft account.',
            error
        });
    }
}
export const actions = {
    getAccount: GyftModule.dispatch(getAccount)
};
// mutations
function setAccount(state, account) {
    state.account = account;
}
export const mutations = {
    setAccount: GyftModule.commit(setAccount)
};
