import { storeBuilder, VIEW_STATE } from '@/store/RootState';
import Client from '@/service/Client';
import * as notificationStore from './NotificationStore';
import { GIFTCARD_STATUS, USER_ROLES } from '@gd-mono/gd-common';
import { GiftCardClient } from '@/service/GiftCardClient';
/**
 * @description Initial GiftCard Store State
 * @author Logan Hendershot
 * @date 11/10/2021
 * @export
 * @class GiftCardStoreState
 */
export class GiftCardState {
    viewState = VIEW_STATE.VIEWING;
    loading = false;
    targetCard = undefined;
    allGiftCards = undefined;
}
// giftcard module builder
const giftCardModule = storeBuilder.module('giftcard', new GiftCardState());
// GiftCard getter functions object
const getterFunctions = {
    loading: giftCardModule.read((state) => state.loading, 'loading'),
    viewState: giftCardModule.read((state) => state.viewState, 'viewState'),
    getTargetCard: giftCardModule.read((state) => {
        return state.targetCard;
    }, 'getTargetCard'),
    allGiftCards: giftCardModule.read((state) => state.allGiftCards, 'allGiftCards'),
    availableGiftCards: giftCardModule.read((state) => state.allGiftCards?.filter((gc) => gc.status === GIFTCARD_STATUS.AVAILABLE), 'availableGiftCards'),
    adminCreatedGiftCards: giftCardModule.read((state) => state.allGiftCards.filter((gc) => gc.createdBy.role === USER_ROLES.ADMIN), 'adminCreatedGiftCards'),
    availableAdminGiftCards: giftCardModule.read((state) => state.allGiftCards.filter((gc) => gc.status === GIFTCARD_STATUS.AVAILABLE &&
        gc.createdBy.role === USER_ROLES.ADMIN), 'availableAdminGiftCards')
};
// GiftCard module getters
export const getters = {
    get loading() {
        return getterFunctions.loading();
    },
    get viewState() {
        return getterFunctions.viewState();
    },
    get targetCard() {
        return getterFunctions.getTargetCard();
    },
    get allGiftCards() {
        return getterFunctions.allGiftCards();
    },
    get availableGiftCards() {
        return getterFunctions.availableGiftCards();
    },
    get adminCreatedGiftCards() {
        return getterFunctions.adminCreatedGiftCards();
    },
    get availableAndAdminGiftCards() {
        return getterFunctions.availableAdminGiftCards();
    }
    // get gyftMerchantsFiltered(): GyftCatalogMerchant[] {
    //     return getterFunctions.gyftMerchantsFiltered()
    // },
    // get gyftMerchantsBeingFiltered(): GyftCatalogMerchant[] {
    //     return getterFunctions.gyftMerchantsBeingFiltered()
    // }
};
// Giftcard Store Module mutations functions
function setLoading(state, payload) {
    state.loading = payload;
}
function setViewState(state, payload) {
    state.viewState = payload;
}
function clearViewState(state) {
    state.viewState = VIEW_STATE.VIEWING;
    state.targetCard = undefined;
}
function setGiftCards(state, payload) {
    state.allGiftCards = payload;
}
// function setGyftGiftCardMerchants(
//     state: GiftCardState,
//     payload: GyftCatalogMerchant[] | null
// ): void {
//     state.gyftMerchants = payload
// }
// function setGyftGiftCardMerchantsFilteredIds(
//     state: GiftCardState,
//     payload: GetGiftCardMerchantsResponse
// ): void {
//     state.gyftMerchantsFilterIds = payload.filterIds
//     state.gyftMerchants = payload.gyftMerchants
// }
// mutations for target card (creation / update)
function setProvider(state, payload) {
    state.targetCard.provider = payload;
}
// function setCardMerchant(state: GiftCardState, payload: string): void {
//     state.targetCard.cardMerchant = payload
// }
function setCardNumber(state, payload) {
    state.targetCard.number = payload;
}
function setCardSubNumber(state, payload) {
    state.targetCard.subNumber = {
        number: payload,
        label: state.targetCard.subNumber.label
    };
}
function setCardSubNumberLabel(state, payload) {
    state.targetCard.subNumber = {
        number: state.targetCard.subNumber.number,
        label: payload
    };
}
function createCardSubNumber(state) {
    state.targetCard.subNumber = { number: '', label: '' };
}
function clearCardSubNumber(state) {
    state.targetCard.subNumber = undefined;
}
function setBarcodeNumber(state, payload) {
    state.targetCard.barcode = { type: 'CODE128', number: payload };
}
function clearBarcode(state) {
    state.targetCard.barcode = undefined;
}
function setCardValue(state, payload) {
    state.targetCard.openingBalance = parseInt(payload);
}
function setBalanceLink(state, payload) {
    // state.targetCard. = payload
}
function setTargetCard(state, payload) {
    state.targetCard = payload;
}
// GiftCard Store module mutations export
export const mutations = {
    setLoading: giftCardModule.commit(setLoading),
    // target card mutations
    setProvider: giftCardModule.commit(setProvider),
    // setCardMerchant: giftCardModule.commit(setCardMerchant),
    setCardNumber: giftCardModule.commit(setCardNumber),
    setCardSubNumber: giftCardModule.commit(setCardSubNumber),
    createCardSubNumber: giftCardModule.commit(createCardSubNumber),
    clearCardSubNumber: giftCardModule.commit(clearCardSubNumber),
    clearBarcode: giftCardModule.commit(clearBarcode),
    setCardSubNumberLabel: giftCardModule.commit(setCardSubNumberLabel),
    setBarcodeNumber: giftCardModule.commit(setBarcodeNumber),
    setCardValue: giftCardModule.commit(setCardValue),
    setBalanceLink: giftCardModule.commit(setBalanceLink),
    setTargetCard: giftCardModule.commit(setTargetCard),
    // other gift card mutations
    setGiftCards: giftCardModule.commit(setGiftCards),
    setViewState: giftCardModule.commit(setViewState),
    clearViewState: giftCardModule.commit(clearViewState)
};
const gcClient = new GiftCardClient();
// action functions
async function getAllGiftCards() {
    mutations.setLoading(true);
    try {
        const giftCards = await gcClient.readAll();
        mutations.setGiftCards(giftCards);
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: 'Unable To Retrieve Gift Cards.',
            error
        });
    }
    mutations.setLoading(false);
}
async function createGiftCard(context, giftCard) {
    mutations.setLoading(true);
    try {
        await gcClient.create(giftCard);
        notificationStore.actions.pushSuccess({
            title: 'Gift Card Created.'
        });
        await getAllGiftCards();
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: 'Unable To Create Gift Card.',
            error
        });
    }
    mutations.setLoading(false);
}
async function assignGiftCards(context, payload) {
    mutations.setLoading(true);
    // try {
    //     await Client.assignGiftCards(payload.targetId, payload.giftCardIds)
    //     notificationStore.actions.pushSuccess({
    //         title: 'Gift Cards Assigned.'
    //     })
    // } catch (error) {
    //     notificationStore.actions.pushError({
    //         title: 'Unable To Assign Gift Cards.',
    //         error
    //     })
    // }
    mutations.setLoading(false);
}
async function updateGiftCard(context, giftCard) {
    mutations.setLoading(true);
    try {
        await gcClient.update(giftCard);
        notificationStore.actions.pushSuccess({
            title: 'Gift Card Updated.'
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: 'Unable To Update Gift Card.',
            error
        });
    }
    mutations.setLoading(false);
}
async function deleteGiftCard(context, giftCard) {
    mutations.setLoading(true);
    try {
        await gcClient.delete(giftCard.id);
        notificationStore.actions.pushSuccess({
            title: 'Gift Card Removed.'
        });
        await getAllGiftCards();
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: 'Unable To Remove Gift Card.',
            error
        });
    }
    mutations.setLoading(false);
}
async function purchaseAndCreateGyftGiftCard(context, payload) {
    mutations.setLoading(true);
    try {
        await Client.purchaseAndCreateGyftGiftCard(payload.merchantId, payload.shopCardId);
        notificationStore.actions.pushSuccess({
            title: 'Gift Card Created.'
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: 'Unable To Create Gift Card.',
            error
        });
    }
    mutations.setLoading(false);
}
async function updateGyftMerchantFilter(context, idsToBeFiltered) {
    mutations.setLoading(true);
    try {
        await Client.updateGyftMerchantFilter(idsToBeFiltered);
        notificationStore.actions.pushSuccess({
            title: 'List Updated.'
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: 'Unable To Update List.',
            error
        });
    }
    mutations.setLoading(false);
}
export const actions = {
    getAllGiftCards: giftCardModule.dispatch(getAllGiftCards),
    createGiftCard: giftCardModule.dispatch(createGiftCard),
    assignGiftCards: giftCardModule.dispatch(assignGiftCards),
    updateGiftCard: giftCardModule.dispatch(updateGiftCard),
    deleteGiftCard: giftCardModule.dispatch(deleteGiftCard),
    // getGiftCardMerchants: giftCardModule.dispatch(getGiftCardMerchants),
    purchaseAndCreateGyftGiftCard: giftCardModule.dispatch(purchaseAndCreateGyftGiftCard),
    updateGyftMerchantFilter: giftCardModule.dispatch(updateGyftMerchantFilter)
};
