var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "mt-5"
  }, [_c('div', {
    staticClass: "mb-5"
  }, [_c('h1', {
    staticClass: "display-3 mt-0"
  }, [_vm._v("What will be received when the drop is redeemed?")]), _c('p', {
    staticClass: "lead text-muted"
  }, [_vm._v(" How does a GiftDrop user 'use' the drop? How will they work with your business? ")])]), _c('b-card-group', {
    attrs: {
      "deck": ""
    }
  }, [_c('OffersCardComponent', {
    attrs: {
      "title": "Generated Barcodes",
      "description": "Barcode text should represent the item or sale in your PoS system that\n                        represents the drop campaign. The Gift Drop user should be able to\n                        redeem the drop by showing the barcode to the cashier.",
      "example": "1231234A4B",
      "imgSrc": "https://via.placeholder.com/300x200?Text=Placeholder",
      "selected": _vm.selectedSource === undefined ? null : _vm.barcodeGenerate
    },
    on: {
      "onSelect": function onSelect($event) {
        return _vm.offerSourceChange({
          inventory_type: 'INVENTORY',
          inventory_sub_type: 'BARCODE_GENERATE'
        });
      }
    }
  }), _c('OffersCardComponent', {
    attrs: {
      "title": "Upload Barcode Images",
      "description": "If you have pre-created barcode images, you can upload them here. This\n                        will be given to the user on redemption and will show in the app. The\n                        Gift Drop user should be able to redeem the drop by showing this image\n                        to the cashier.",
      "example": "A pre-created QR code",
      "imgSrc": "https://via.placeholder.com/300x200?Text=Placeholder",
      "selected": _vm.selectedSource === undefined ? null : _vm.barcodeInventory
    },
    on: {
      "onSelect": function onSelect($event) {
        return _vm.offerSourceChange({
          inventory_type: 'INVENTORY',
          inventory_sub_type: 'BARCODE_IMAGE'
        });
      }
    }
  }), _c('OffersCardComponent', {
    attrs: {
      "title": "A Sale or Discount Code Text",
      "description": "This will be a text code that a Gift Drop user can use once redeemed to\n                        claim the sale or discount. This will either be shown to the cashier or\n                        used digitally.",
      "example": "WINTER23",
      "imgSrc": "https://via.placeholder.com/300x200?Text=Placeholder",
      "selected": _vm.selectedSource === undefined ? null : _vm.textVirtual
    },
    on: {
      "onSelect": function onSelect($event) {
        return _vm.offerSourceChange({
          inventory_type: 'VIRTUAL',
          inventory_sub_type: 'DISCOUNT_TEXT'
        });
      }
    }
  }), _c('OffersCardComponent', {
    attrs: {
      "title": "Upload PDF Files",
      "description": "If your drop is redeemable by showing a PDF file, you can upload it\n                        here.",
      "example": "Concert or event tickets",
      "imgSrc": "https://via.placeholder.com/300x200?Text=Placeholder",
      "selected": _vm.selectedSource === undefined ? null : _vm.pdfInventory
    },
    on: {
      "onSelect": function onSelect($event) {
        return _vm.offerSourceChange({
          inventory_type: 'INVENTORY',
          inventory_sub_type: 'PDF'
        });
      }
    }
  })], 1), _c('SlideYUpTransition', [_c('b-alert', {
    staticClass: "my-5",
    attrs: {
      "variant": "secondary",
      "show": _vm.selectedSource !== undefined
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "info-circle-fill",
      "scale": "2",
      "variant": "info"
    }
  })], 1), _c('b-col', [_c('h1', {
    staticClass: "display-3 d-inline"
  }, [_vm._v(" You'll upload inventory on drop deployment. ")]), _c('p', {
    staticClass: "lead text-muted"
  }, [_vm._v(" When you deploy your drop to the map, you'll be able to load your inventory for the drop and users who redeem it. ")]), _c('base-button', {
    attrs: {
      "type": "primary",
      "disabled": !_vm.selectedSource
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v(" Next "), _c('BIconArrowRight', {
    staticClass: "ml-2"
  })], 1)], 1)], 1)], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }