import Axios from "axios";
import { REQUEST_METHOD } from "@gd-mono/gd-common";
import Client, { API_URL } from "./Client";
export class OfferClient {
    async create(offer) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.POST,
            url: `${API_URL}/offers`,
            data: offer,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
    async read(id) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: `${API_URL}/offers/${id}`,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
    /**
     * @description Updates an offer
     * @author Logan Hendershot
     * @date 03/15/2023
     * @param {OfferModel} offer
     * @returns {*}  {Promise<OfferModel>}
     * @memberof OfferClient
     */
    async update(offer) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.PUT,
            url: `${API_URL}/offers/${offer.id}`,
            data: offer,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
    async readAll() {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: `${API_URL}/offers`,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
    async delete(id) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.DELETE,
            url: `${API_URL}/offers/${id}`,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
}
