var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit,
          valid = _ref.valid;
        return [_c('b-modal', {
          directives: [{
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }],
          attrs: {
            "id": "offers-modal",
            "size": "xl",
            "scrollable": "",
            "hide-header-close": ""
          },
          on: {
            "hidden": _vm.onClose
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn() {
              return [_c('form', {
                on: {
                  "submit": function submit($event) {
                    //@ts-ignore
                    handleSubmit(_vm.handleSave);
                  }
                }
              }, [_c('b-row', {
                attrs: {
                  "id": "offers-modal-footer"
                }
              }, [_c('b-col', {
                staticClass: "d-flex"
              }, [_c('base-button', {
                staticClass: "ml-auto",
                attrs: {
                  "type": "link"
                },
                on: {
                  "click": function click($event) {
                    return _vm.onClose();
                  }
                }
              }, [_vm._v(" Cancel ")]), _vm.viewing ? _c('b-button', {
                staticClass: "ml-auto",
                attrs: {
                  "variant": "outline-primary"
                },
                on: {
                  "click": function click($event) {
                    return _vm.onEdit();
                  }
                }
              }, [_vm._v(" Edit ")]) : _vm._e(), _c('base-button', {
                attrs: {
                  "type": "primary",
                  "native-type": "submit",
                  "disabled": _vm.loading || !valid || _vm.viewing
                }
              }, [_vm._v(" " + _vm._s(_vm.editing || _vm.viewing ? 'Update' : 'Create') + " ")])], 1)], 1)], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          attrs: {
            "id": "offer-type-select"
          }
        }, [_c('OffersTypeSelect')], 1)])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }