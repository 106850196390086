<template>
    <b-breadcrumb-item :active="active" v-show="show">
        <slot></slot>
    </b-breadcrumb-item>
</template>
<script>
export default {
    name: 'breadcrumb-item',
    props: {
        active: {
            type: Boolean,
            default: false,
            description: 'Whether breadcrumb item is active'
        },
        show: {
            type: Boolean,
            default: true,
            description: 'Whether breadcrumb should be shown'
        }
    }
}
</script>
<style></style>
