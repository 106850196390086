<template>
    <div>
        <base-header class="pb-6 profile-header">
            <span class="mask bg-gradient-primary opacity-8"></span>
            <b-row align-v="center" class="py-4">
                <b-col cols="7" lg="6">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <RouteBreadCrumb />
                    </nav>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col md="10" v-if="!loading && company">
                    <h1 class="display-2 text-white">
                        {{ company.name }}
                    </h1>
                    <p class="text-light">
                        {{ company.id }}
                    </p>
                </b-col>
            </b-row>
        </base-header>
        <b-container class="mt--5">
            <b-row>
                <b-col>
                    <MerchantCompanyCard />
                    <CompanyMembersList isPending v-if="!loading && company" />
                    <CompanyMembersList v-if="!loading && company" />
                    <CampaignsListTable
                        v-if="isAdmin && company"
                        :hasActions="false"
                        :hasCompanyColumn="false"
                        :companyId="company.id" />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Vue from 'vue'

import * as companyStore from '@/store/modules/CompanyStore'
import * as authStore from '@/store/modules/AuthStore'
import RouteBreadCrumb from '@/argon/components/Breadcrumb/RouteBreadcrumb.vue'
import CompanyMembersList from '@/components/companies/MembersList.vue'
import MerchantCompanyCard from '@/components/companies/MerchantCompanyCard.vue'
import CampaignsListTable from '@/components/campaigns/CampaignsListTable.vue'

export default Vue.extend({
    name: 'CompanyDetails',
    components: {
        RouteBreadCrumb,
        MerchantCompanyCard,
        CompanyMembersList,
        CampaignsListTable
    },
    async mounted() {
        if (this.isAdmin) {
            await companyStore.actions.getCompany(this.$route.params.id)
        } else if (!this.company) {
            await companyStore.actions.getCompany(this.userCompanyId)
        }
    },
    computed: {
        loading() {
            return companyStore.getters.getLoading
        },
        company() {
            return companyStore.getters.getCompany
        },
        isAdmin() {
            return authStore.getters.isAdmin
        },
        userCompanyId() {
            return authStore.getters.getUser.companyId
        }
    }
})
</script>
