var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert alert-notify alert-dismissible d-flex align-items-center",
    class: [{
      'alert-with-icon': _vm.icon
    }, _vm.verticalAlign, _vm.horizontalAlign, _vm.alertType],
    style: _vm.customPosition,
    attrs: {
      "data-notify": "container",
      "role": "alert",
      "data-notify-position": "top-center"
    },
    on: {
      "click": _vm.tryClose
    }
  }, [_vm.icon || _vm.$slots.icon ? [_vm._t("icon", function () {
    return [_c('span', {
      staticClass: "alert-icon",
      attrs: {
        "data-notify": "icon"
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": _vm.icon,
        "font-scale": "1.5"
      }
    })], 1)];
  })] : _vm.type === 'warning' ? [_vm._t("icon", function () {
    return [_c('span', {
      staticClass: "alert-icon",
      attrs: {
        "data-notify": "icon"
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "exclamation-triangle",
        "font-scale": "1.5"
      }
    })], 1)];
  })] : _vm.type === 'success' ? [_vm._t("icon", function () {
    return [_c('span', {
      staticClass: "alert-icon",
      attrs: {
        "data-notify": "icon"
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "check2-circle",
        "font-scale": "1.5"
      }
    })], 1)];
  })] : _vm._e(), _c('span', {
    staticClass: "alert-text"
  }, [_vm.title ? _c('span', {
    staticClass: "title"
  }, [_c('b', [_vm._v(_vm._s(_vm.title)), _c('br')])]) : _vm._e(), _vm.message ? _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  }) : _vm._e(), !_vm.message && _vm.component ? _c('content-render', {
    attrs: {
      "component": _vm.component
    }
  }) : _vm._e()], 1), _vm._t("dismiss-icon", function () {
    return [_c('button', {
      staticClass: "close align-self-start",
      attrs: {
        "type": "button",
        "data-dismiss": "alert",
        "aria-label": "Close"
      },
      on: {
        "click": _vm.close
      }
    }, [_c('span', {
      attrs: {
        "aria-hidden": "true"
      }
    }, [_vm._v("×")])])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }