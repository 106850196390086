var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    staticClass: "py-5 h-100",
    attrs: {
      "id": "whats-giftdrop"
    }
  }, [_c('b-row', {
    staticClass: "h-100 w-fluid text-center m-auto"
  }, [_c('b-col', {
    staticClass: "d-flex flex-column justify-content-center align-items-center text-white"
  }, [_c('img', {
    staticClass: "mb-4",
    attrs: {
      "src": require("@/assets/img/jpg-logo.png"),
      "height": "94px",
      "width": "94px",
      "alt": "giftdrop"
    }
  }), _c('h1', {
    staticClass: "display-2"
  }, [_vm._v("Download the app now.")]), _c('p', {
    staticClass: "lead text-dark mt-1"
  }, [_vm._v(" An enim nullam tempor sapien gravida donec enim ipsum porta justo integer at odio velna vitae auctor integer congue magna at pretium puru. ")]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/img/app-store-button.png"),
      "width": "255px",
      "alt": "app store button"
    }
  }), _c('img', {
    attrs: {
      "src": require("@/assets/img/google-play-badge.png"),
      "width": "300px",
      "alt": "google play badge"
    }
  })])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }