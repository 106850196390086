import Axios from 'axios';
import { CAMPAIGNS_PATCH_ACTION, REQUEST_METHOD } from '@gd-mono/gd-common';
import ENDPOINTS from '../util/endpoints';
import Client, { API_URL } from './Client';
/**
 * @description Handles interactions with the Campaigns API
 * @author Logan Hendershot
 * @date 03/09/2023
 * @export
 * @abstract
 * @class CampaignClient
 */
export default class CampaignClient {
    static async getCampaigns() {
        const authToken = await Client.getAuthToken();
        let campaigns = [];
        const response = await Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + ENDPOINTS.CAMPAIGNS,
            headers: {
                authToken
            }
        });
        campaigns = response.data.data;
        return campaigns;
    }
    static async getCampaign(id) {
        const authToken = await Client.getAuthToken();
        return Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + ENDPOINTS.CAMPAIGNS + '/' + id,
            headers: {
                authToken
            }
        });
    }
    static async createCampaign(data) {
        const authToken = await Client.getAuthToken();
        return Axios({
            method: REQUEST_METHOD.POST,
            data: data,
            url: API_URL + '/campaigns',
            headers: {
                authToken
            }
        });
    }
    static async deleteCampaign(id, archive) {
        const authToken = await Client.getAuthToken();
        return Axios({
            method: REQUEST_METHOD.PATCH,
            data: {
                action: CAMPAIGNS_PATCH_ACTION.ARCHIVE,
                payload: archive
            },
            url: API_URL + ENDPOINTS.CAMPAIGNS + '/' + id,
            headers: {
                authToken
            }
        });
    }
    static async pauseCampaign(id, pause) {
        const authToken = await Client.getAuthToken();
        return Axios({
            method: REQUEST_METHOD.PATCH,
            data: {
                action: CAMPAIGNS_PATCH_ACTION.PAUSE,
                payload: pause
            },
            url: API_URL + ENDPOINTS.CAMPAIGNS + '/' + id,
            headers: {
                authToken
            }
        });
    }
    static async lockCampaign(id, block) {
        const authToken = await Client.getAuthToken();
        return Axios({
            method: REQUEST_METHOD.PATCH,
            data: {
                action: CAMPAIGNS_PATCH_ACTION.BLOCK,
                payload: block
            },
            url: API_URL + ENDPOINTS.CAMPAIGNS + '/' + id,
            headers: {
                authToken
            }
        });
    }
    static async updateCampaign(id, data) {
        const authToken = await Client.getAuthToken();
        return Axios({
            method: REQUEST_METHOD.PUT,
            data: data,
            url: API_URL + ENDPOINTS.CAMPAIGNS + '/' + id,
            headers: {
                authToken
            }
        });
    }
    static async getCampaignAnalytics(id) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + ENDPOINTS.CAMPAIGNS + `/${id}/analytics`,
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
}
