var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.initialLoaded ? _c('router-view') : _vm._e(), _vm.basicModal.show ? _c('basic-modal', {
    attrs: {
      "basic-modal": _vm.basicModal.opts
    }
  }) : _vm._e(), _c('ChangelogModal'), _c('GiftCardModal'), _c('OffersModal'), _c('ConfirmationModal', {
    attrs: {
      "show": _vm.confirmationModal.show,
      "title": _vm.confirmationModal.data.title,
      "icon": _vm.confirmationModal.data.icon,
      "iconVariant": _vm.confirmationModal.data.iconVariant,
      "headerText": _vm.confirmationModal.data.headerText,
      "bodyText": _vm.confirmationModal.data.bodyText,
      "confirmText": _vm.confirmationModal.data.confirmText,
      "cancelText": _vm.confirmationModal.data.cancelText
    },
    on: {
      "update:show": function updateShow($event) {
        return _vm.$set(_vm.confirmationModal, "show", $event);
      },
      "confirmAction": _vm.confirmationModal.data.confirmAction,
      "cancelAction": this.closeConfirmationModal
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }