import Notifications from './Notifications.vue';
import * as notificationStore from '@/store/modules/NotificationStore';
const NotificationsPlugin = {
    install(Vue) {
        const app = new Vue({
            data: {
                notificationStore: notificationStore
            },
            methods: {
                notify(notification) {
                    notificationStore.actions.pushNotificationAction(notification);
                }
            }
        });
        Vue.prototype.$notify = app.notify;
        Vue.prototype.$notifications = app.notificationStore;
        Vue.component('Notifications', Notifications);
    }
};
export default NotificationsPlugin;
