/* eslint-disable */
import { PaymentClient } from '@/service/PaymentClient';
import { storeBuilder } from '../RootState';
import * as notificationStore from './NotificationStore';
/**
 * @description Base store state for handling payments data
 * @author Alejandro Nieto
 * @date 8/17/2022
 * @export
 * @class PaymentState
 */
export class PaymentState {
    loading = false;
    customer = null;
}
const paymentClient = new PaymentClient();
// use module builder
const paymentModule = storeBuilder.module('payment', new PaymentState());
// getters
const getterFunctions = {
    getCustomer: paymentModule.read((state) => state.customer, 'getCustomer'),
    hasPayment: paymentModule.read((state) => {
        const customer = state.customer;
        return customer && customer.default_source !== null;
    }, 'hasPayment'),
    getLoading: paymentModule.read((state) => state.loading, 'getLoading')
};
export const getters = {
    get getCustomer() {
        return getterFunctions.getCustomer();
    },
    get getLoading() {
        return getterFunctions.getLoading();
    },
    get hasPayment() {
        return getterFunctions.hasPayment();
    }
};
// mutations
const mutationFunctions = {
    setCustomer(state, customer) {
        state.customer = customer;
    },
    setLoading(state, loading) {
        state.loading = loading;
    }
};
export const mutations = {
    setCustomer: paymentModule.commit(mutationFunctions.setCustomer),
    setLoading: paymentModule.commit(mutationFunctions.setLoading)
};
async function fetchCustomer(context) {
    try {
        mutations.setLoading(true);
        const user = context.rootState.auth.user;
        if (!user.stripeCustomerId) {
            // no op
            mutations.setLoading(false);
            return;
        }
        const customer = await paymentClient.read(user.id);
        mutations.setCustomer(customer);
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: 'Error getting payments.',
            error
        });
    }
    finally {
        mutations.setLoading(false);
    }
}
async function createCard(context, token) {
    try {
        mutations.setLoading(true);
        const userId = context.rootState.auth.user.id;
        const customer = await paymentClient.createCard(token, userId);
        mutations.setCustomer(customer);
        notificationStore.actions.pushSuccess({
            title: 'Successfully created card.'
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: 'Error creating card.',
            error
        });
    }
    finally {
        mutations.setLoading(false);
    }
}
async function deleteCard(context, cardId) {
    try {
        mutations.setLoading(true);
        const userId = context.rootState.auth.user.id;
        const customer = await paymentClient.deleteCard(cardId, userId);
        notificationStore.actions.pushSuccess({
            title: 'Successfully deleted card.'
        });
        mutations.setCustomer(customer);
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: 'Error deleting card.',
            error
        });
    }
    finally {
        mutations.setLoading(false);
    }
}
async function updateDefaultCard(context, cardId) {
    try {
        mutations.setLoading(true);
        const userId = context.rootState.auth.user.id;
        const customer = await paymentClient.updateDefaultCard(cardId, userId);
        mutations.setCustomer(customer);
        notificationStore.actions.pushSuccess({
            title: 'Successfully updated default.'
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: 'Error updating default card.',
            error
        });
    }
    finally {
        mutations.setLoading(false);
    }
}
export const actions = {
    fetchCustomer: paymentModule.dispatch(fetchCustomer),
    createCard: paymentModule.dispatch(createCard),
    deleteCard: paymentModule.dispatch(deleteCard),
    updateDefaultCard: paymentModule.dispatch(updateDefaultCard)
};
