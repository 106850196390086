import Axios from "axios";
import { REQUEST_METHOD } from "@gd-mono/gd-common";
import Client, { API_URL } from "./Client";
/**
 * @description GratitudeClient
 * @date 08/23/2022
 * @export default
 * @class GratitudeClient
 */
export default class GratitudeClient {
    static async create(gratitude) {
        const authToken = await Client.getAuthToken();
        console.log(gratitude, "gratitude");
        const resp = await Axios({
            method: REQUEST_METHOD.POST,
            url: `${API_URL}/gratitudes`,
            headers: {
                authToken,
            },
            data: {
                gratitude,
            },
        });
        return resp.data.data;
    }
    static async readAll() {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: `${API_URL}/gratitudes`,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
    static async update(gratitude) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.PUT,
            url: `${API_URL}/gratitudes/${gratitude.id}`,
            headers: {
                authToken,
            },
            data: gratitude,
        });
        return resp.data.data;
    }
    static async delete(gratitudeId) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            url: `${API_URL}/gratitudes/${gratitudeId}`,
            method: REQUEST_METHOD.DELETE,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
}
