import Axios from "axios";
import { REQUEST_METHOD } from "@gd-mono/gd-common";
import Client, { API_URL } from "./Client";
export class AvatarClient {
    static instance;
    constructor() { }
    static getInstance() {
        if (!AvatarClient.instance) {
            AvatarClient.instance = new AvatarClient();
        }
        return AvatarClient.instance;
    }
    async create(avatar) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.POST,
            url: `${API_URL}/avatars`,
            headers: {
                authToken,
            },
            data: {
                avatar,
            },
        });
        return resp.data.data;
    }
    async readAll() {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: `${API_URL}/avatars`,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
    async update(avatar) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.PUT,
            url: `${API_URL}/avatars/${avatar.id}`,
            headers: {
                authToken,
            },
            data: avatar,
        });
        return resp.data.data;
    }
    async delete(avatarId) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            url: `${API_URL}/avatars/${avatarId}`,
            method: REQUEST_METHOD.DELETE,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
}
