var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isYouTube ? _c('LazyYoutube', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.url
    }
  }) : _vm.isVimeo ? _c('LazyVimeo', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.url
    }
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }