<template>
    <div>
        <b-row no-gutters>
            <b-col>
                <base-header class="pb-6 profile-header">
                    <span class="mask bg-gradient-info opacity-8"></span>
                    <b-row align-v="center" class="py-4">
                        <b-col cols="7" lg="6">
                            <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                                <RouteBreadCrumb />
                            </nav>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col md="10">
                            <h1 class="display-2 text-white">Transaction Management</h1>
                        </b-col>
                    </b-row>
                </base-header>
            </b-col>
        </b-row>
        <b-container>
            <b-row class="mt--6" no-gutters>
                <b-col>
                    <TransactionListTable />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import RouteBreadCrumb from '@/argon/components/Breadcrumb/RouteBreadcrumb.vue'
import TransactionListTable from '@/components/transactions/TransactionListTable'

export default {
    name: 'TransactionList',
    components: {
        RouteBreadCrumb,
        TransactionListTable
    }
}
</script>
