var render = function render(){
  var _vm$company, _vm$company2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "mb-4"
  }, [_c('b-row', {
    attrs: {
      "slot": "header",
      "align-v": "center"
    },
    slot: "header"
  }, [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v(" Your Company Profile "), _vm.company ? _c('b-badge', {
    staticClass: "ml-2",
    attrs: {
      "variant": (_vm$company = _vm.company) !== null && _vm$company !== void 0 && _vm$company.approved ? 'success' : 'primary'
    }
  }, [_vm._v(" " + _vm._s((_vm$company2 = _vm.company) !== null && _vm$company2 !== void 0 && _vm$company2.approved ? 'Approved' : 'Pending Approval') + " ")]) : _vm._e(), _vm.company && !_vm.isAdmin ? _c('b-badge', {
    staticClass: "ml-2",
    attrs: {
      "variant": _vm.isMerchantOwner ? 'info' : 'secondary'
    }
  }, [_vm._v(" " + _vm._s(_vm.isMerchantOwner ? 'Owner' : 'Member') + " ")]) : _vm._e()], 1)]), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "cols": "4"
    }
  }, [!_vm.editing && !_vm.creating ? _c('b-button', {
    staticClass: "ml-auto p-0",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.setEditing
    }
  }, [_vm._v(" Edit ")]) : _vm._e(), _vm.editing ? _c('b-button', {
    staticClass: "ml-auto p-0",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.setViewing
    }
  }, [_vm._v(" Cancel ")]) : _vm._e()], 1)], 1), _vm.showFindOrCreateCompany ? _c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('FindOrCreateCompany')], 1) : _c('b-row', [_c('b-col', [_c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$company3, _vm$company4, _vm$company5, _vm$company6, _vm$company7, _vm$company8, _vm$company9, _vm$company10, _vm$company11, _vm$company12;
        var handleSubmit = _ref.handleSubmit,
          invalid = _ref.invalid;
        return [_c('b-form-group', {
          attrs: {
            "disabled": _vm.viewing
          }
        }, [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.handleSave);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('base-input', {
          attrs: {
            "type": "text",
            "label": "Company Name *",
            "name": "Company Name",
            "rules": "required|max:30",
            "value": (_vm$company3 = _vm.company) === null || _vm$company3 === void 0 ? void 0 : _vm$company3.name
          },
          on: {
            "input": _vm.setName
          }
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('base-text-area', {
          attrs: {
            "type": "text",
            "rows": 8,
            "label": "Company Description *",
            "name": "Company Description",
            "rules": "required|min:50",
            "value": (_vm$company4 = _vm.company) === null || _vm$company4 === void 0 ? void 0 : _vm$company4.description
          },
          on: {
            "input": _vm.setDescription
          }
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('TagsInput', {
          attrs: {
            "value": (_vm$company5 = _vm.company) === null || _vm$company5 === void 0 ? void 0 : _vm$company5.tags
          },
          on: {
            "change": _vm.setTags
          }
        })], 1)], 1), _vm.companyLogo ? _c('b-row', {
          staticClass: "my-3 h-50"
        }, [_c('b-col', [_c('span', {
          staticClass: "form-control-label d-block"
        }, [_vm._v(" Company Logo ")]), _c('div', {
          staticClass: "d-flex justify-content-start"
        }, [_c('div', {
          staticClass: "d-flex flex-column justify-content-center align-items-center"
        }, [_c('b-img', {
          staticClass: "my-3 merchant-logo-img",
          attrs: {
            "thumbnail": "",
            "fluid": "",
            "rounded": "circle",
            "src": _vm.companyLogo,
            "alt": "Company Logo"
          }
        }), _c('b-button', {
          attrs: {
            "variant": "link",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.setLogoUrl(null);
            }
          }
        }, [_vm._v(" Remove ")])], 1)])])], 1) : _c('ElementUploadValidation', {
          attrs: {
            "fluidGrow": "",
            "rules": "required",
            "label": "Company Logo *",
            "listType": "picture",
            "inputClass": "uploader",
            "accept": "image/png, image/jpeg, image/webp",
            "value": _vm.companyLogo,
            "httpRequest": _vm.handleLogoUpload,
            "showFileList": false,
            "beforeUpload": _vm.beforeImageUpload
          }
        }), _vm.companyCover ? _c('b-row', {
          staticClass: "my-3 h-50"
        }, [_c('b-col', [_c('span', {
          staticClass: "form-control-label d-block"
        }, [_vm._v(" Company Cover ")]), _c('div', {
          staticClass: "d-flex justify-content-start"
        }, [_c('div', {
          staticClass: "d-flex flex-column justify-content-center align-items-center"
        }, [_c('b-img', {
          staticClass: "my-3 merchant-logo-img",
          attrs: {
            "thumbnail": "",
            "fluid": "",
            "rounded": "circle",
            "src": _vm.companyCover,
            "alt": "Company Cover"
          }
        }), _c('b-button', {
          attrs: {
            "variant": "link",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.setCoverUrl(null);
            }
          }
        }, [_vm._v(" Remove ")])], 1)])])], 1) : _c('ElementUploadValidation', {
          attrs: {
            "fluidGrow": "",
            "rules": "required",
            "label": "Company Cover Image *",
            "listType": "picture",
            "inputClass": "uploader",
            "accept": "image/png, image/jpeg, image/webp",
            "value": _vm.companyCover,
            "httpRequest": _vm.handleCompanyCover,
            "showFileList": false,
            "beforeUpload": _vm.beforeImageUpload
          }
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('base-input', {
          attrs: {
            "type": "text",
            "label": "Company Phone *",
            "name": "Company Phone",
            "rules": "required|phone",
            "value": (_vm$company6 = _vm.company) === null || _vm$company6 === void 0 ? void 0 : _vm$company6.phone
          },
          on: {
            "input": _vm.setPhone
          }
        }), _c('base-input', {
          attrs: {
            "type": "text",
            "label": "Company Address *",
            "name": "Company Address",
            "rules": "required",
            "value": (_vm$company7 = _vm.company) === null || _vm$company7 === void 0 ? void 0 : _vm$company7.address
          },
          on: {
            "input": _vm.setAddress
          }
        }), _c('base-input', {
          attrs: {
            "type": "text",
            "label": "Company Email *",
            "name": "Company Email",
            "rules": "required|email",
            "value": (_vm$company8 = _vm.company) === null || _vm$company8 === void 0 ? void 0 : _vm$company8.email
          },
          on: {
            "input": _vm.setEmail
          }
        }), _c('base-input', {
          attrs: {
            "type": "text",
            "label": "Company Website *",
            "name": "Company Website",
            "rules": "required|url",
            "value": (_vm$company9 = _vm.company) === null || _vm$company9 === void 0 ? void 0 : _vm$company9.url
          },
          on: {
            "input": _vm.setCompanyUrl
          }
        }), _c('base-input', {
          attrs: {
            "type": "text",
            "label": "Twitter Url",
            "name": "Twitter Url",
            "rules": "url",
            "value": (_vm$company10 = _vm.company) === null || _vm$company10 === void 0 ? void 0 : _vm$company10.twitterUrl
          },
          on: {
            "input": _vm.setTwitterUrl
          }
        }), _c('base-input', {
          attrs: {
            "type": "text",
            "label": "Facebook Url",
            "name": "Facebook Url",
            "rules": "url",
            "value": (_vm$company11 = _vm.company) === null || _vm$company11 === void 0 ? void 0 : _vm$company11.facebookUrl
          },
          on: {
            "input": _vm.setFacebookUrl
          }
        }), _c('base-input', {
          attrs: {
            "type": "text",
            "label": "Instagram Url",
            "name": "Instagram Url",
            "rules": "url",
            "value": (_vm$company12 = _vm.company) === null || _vm$company12 === void 0 ? void 0 : _vm$company12.instagramUrl
          },
          on: {
            "input": _vm.setInstaUrl
          }
        })], 1)], 1), _c('b-row', [_c('b-col', [_c('CompanyLocations')], 1)], 1), _c('base-button', {
          attrs: {
            "type": "primary",
            "native-type": "submit",
            "disabled": _vm.loading || invalid || _vm.viewing
          }
        }, [_vm._v(" Save Company ")])], 1)])];
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }