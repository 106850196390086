var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-alert', {
    staticClass: "my-5",
    attrs: {
      "variant": "secondary",
      "show": _vm.show
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "info-circle-fill",
      "scale": "2",
      "variant": "info"
    }
  })], 1), _c('b-col', [_c('h1', {
    staticClass: "display-3 d-inline"
  }, [_vm._v("You'll upload inventory on drop deployment.")]), _c('p', {
    staticClass: "lead text-muted"
  }, [_vm._v(" When you deploy your drop to the map, you'll be able to load your inventory for the drop and users who redeem it. ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }