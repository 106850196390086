var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-navbar', {
    staticClass: "cust-nav",
    attrs: {
      "sticky": "",
      "toggleable": "lg"
    }
  }, [_c('b-navbar-brand', {
    staticClass: "justify-content-center align-items-center",
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "d-flex navbar-logo-container"
  }, [_c('img', {
    staticClass: "d-inline-block align-top navbar-logo",
    attrs: {
      "src": require("@/assets/img/LogoWithText.png"),
      "width": "180px",
      "height": "60px",
      "alt": "giftdrop"
    }
  })])]), _c('b-navbar-toggle', {
    attrs: {
      "target": "navbar-toggle-collapse"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var expanded = _ref.expanded;
        return [expanded ? _c('b-icon', {
          attrs: {
            "icon": "chevron-bar-up"
          }
        }) : _c('b-icon', {
          attrs: {
            "icon": "chevron-bar-down"
          }
        })];
      }
    }])
  }), _c('b-collapse', {
    staticClass: "justify-content-end",
    attrs: {
      "id": "navbar-toggle-collapse",
      "is-nav": ""
    }
  }, [_c('b-navbar-nav', {
    staticClass: "mt-2 mb-3 font-weight-bold text-dark"
  }, [_c('b-nav-item', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Home")]), _c('b-nav-item', {
    attrs: {
      "to": "/terms"
    }
  }, [_vm._v("Terms of Service")]), _c('b-nav-item', {
    attrs: {
      "to": "/privacy"
    }
  }, [_vm._v("Privacy Policy")]), _c('b-nav-item', {
    attrs: {
      "href": "mailto:support@giftdrop.com"
    }
  }, [_vm._v("Support")]), _c('b-nav-item', {
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("Login")]), _c('b-nav-item', {
    attrs: {
      "to": "/create"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v("Sign Up")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }