var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "mt-5"
  }, [_c('div', {
    staticClass: "mb-5"
  }, [_c('h1', {
    staticClass: "display-3 mt-0"
  }, [_vm._v("What kind of discount are you offering?")]), _c('p', {
    staticClass: "lead text-muted"
  }, [_vm._v(" 20% off Winter Sale? BOGO? Free item with purchase? Describe your drop to GiftDrop users. ")])]), _c('b-card-group', {
    attrs: {
      "deck": ""
    }
  }, [_c('OffersCardComponent', {
    attrs: {
      "title": "Discount on Purchase Total",
      "description": "A discount that will be applied to the whole purchase amount.",
      "example": "20% Off All Purchases",
      "imgSrc": "https://via.placeholder.com/300x200?Text=Placeholder",
      "selected": _vm.selectedItem === undefined ? null : _vm.discountTotal
    },
    on: {
      "onSelect": function onSelect($event) {
        return _vm.offerDiscountTypeSelect('DISCOUNT_TOTAL');
      }
    }
  }), _c('OffersCardComponent', {
    attrs: {
      "title": "Discount on a Specific Item",
      "description": "A discount will be applied to a specific item or category of items.",
      "example": "10% Oat Milk Latte's",
      "imgSrc": "https://via.placeholder.com/300x200?Text=Placeholder",
      "selected": _vm.selectedItem === undefined ? null : _vm.discountItem
    },
    on: {
      "onSelect": function onSelect($event) {
        return _vm.offerDiscountTypeSelect('DISCOUNT_ITEM');
      }
    }
  }), _c('OffersCardComponent', {
    attrs: {
      "title": "Buy one get one free",
      "description": "A discount that gives a free item with a purchase of equal or lesser\n                        value.",
      "example": "Buy one medium fry get a medium soft drink free",
      "imgSrc": "https://via.placeholder.com/300x200?Text=Placeholder",
      "selected": _vm.selectedItem === undefined ? null : _vm.discountBogo
    },
    on: {
      "onSelect": function onSelect($event) {
        return _vm.offerDiscountTypeSelect('DISCOUNT_BOGO');
      }
    }
  }), _c('OffersCardComponent', {
    attrs: {
      "title": "Free Item Giveaway",
      "description": "This free discount will allow you to give away a free item to the user.",
      "example": "Free reuseable bag with purchase.",
      "imgSrc": "https://via.placeholder.com/300x200?Text=Placeholder",
      "selected": _vm.selectedItem === undefined ? null : _vm.discountFree
    },
    on: {
      "onSelect": function onSelect($event) {
        return _vm.offerDiscountTypeSelect('DISCOUNT_FREE');
      }
    }
  })], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }