var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BaseHeader', {
    staticClass: "pb-6 profile-header"
  }, [_c('span', {
    staticClass: "mask bg-gradient-info opacity-8"
  }), _c('b-row', {
    staticClass: "py-4",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "7",
      "lg": "6"
    }
  }, [_c('nav', {
    staticClass: "d-none d-md-inline-block",
    attrs: {
      "aria-label": "breadcrumb"
    }
  }, [_c('RouteBreadCrumb')], 1)])], 1), _c('b-row', {
    staticClass: "my-1"
  }, [_c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('h1', {
    staticClass: "display-2 text-white"
  }, [_vm._v("Avatar Management")])])], 1)], 1), _c('b-container', {
    staticClass: "mt--5"
  }, [_c('b-card', [_c('h2', [_vm._v("Create Avatar")]), _c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    staticStyle: {
      "width": "140px"
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', [_vm._v("Avatar Image")])]), _c('b-col', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px"
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('input', {
    ref: "file",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.uploadFile
    }
  }), _c('div', {
    directives: [{
      name: "b-hover",
      rawName: "v-b-hover",
      value: _vm.handleHover,
      expression: "handleHover"
    }]
  }, [_vm.isHovered ? _c('b-avatar', {
    attrs: {
      "button": "",
      "icon": "upload",
      "size": "8rem"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.file.click();
      }
    }
  }) : _vm.newAvatarImageUrl ? _c('b-avatar', {
    attrs: {
      "src": _vm.newAvatarImageUrl,
      "size": "8rem"
    }
  }) : _c('b-avatar', {
    attrs: {
      "icon": "people-fill",
      "size": "8rem"
    }
  }), _c('p', {
    staticClass: "text-muted"
  }, [_vm._v("*Image must be square.")])], 1), _c('b-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.newAvatarImageUrl,
      expression: "newAvatarImageUrl"
    }],
    staticClass: "my-2",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.removeAvatarImage();
      }
    }
  }, [_vm._v(" Remove Image ")])], 1)], 1), _c('b-row', {
    staticClass: "justify-content-center mt-4"
  }, [_c('b-col', {
    staticStyle: {
      "width": "140px"
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', [_vm._v("Avatar Title")])]), _c('b-col', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px"
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Avatar Title"
    },
    model: {
      value: _vm.newAvatarTitle,
      callback: function callback($$v) {
        _vm.newAvatarTitle = $$v;
      },
      expression: "newAvatarTitle"
    }
  })], 1)], 1), _c('div', {
    staticClass: "text-right"
  }, [_c('b-button', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !_vm.newAvatarImageUrl || !_vm.newAvatarTitle,
      "variant": "success"
    },
    on: {
      "click": _vm.createNewAvatar
    }
  }, [_vm._v(" Create ")])], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-5 mx-3",
    staticStyle: {
      "border-bottom": "2px solid #d3d3d3"
    }
  })], 1), _c('b-row', [_c('b-col', [_c('h2', [_vm._v("Avatars In Rotation: " + _vm._s(_vm.activeAvatars.length))]), _c('div', {
    staticClass: "d-flex flex-row flex-start flex-wrap my-3"
  }, _vm._l(_vm.activeAvatars, function (avatar) {
    return _c('div', {
      key: avatar.imageUrl,
      staticClass: "d-flex flex-column justify-content-center align-items-center mx-3 my-3"
    }, [_c('b-avatar', {
      attrs: {
        "src": avatar.imageUrl,
        "size": "8rem"
      }
    }), _c('h6', {
      staticClass: "my-2"
    }, [_vm._v(_vm._s(avatar.title))]), _c('a', {
      staticClass: "text-underline action my-2",
      staticStyle: {
        "cursor": "pointer",
        "color": "#5e72e4"
      },
      on: {
        "click": function click($event) {
          return _vm.archive(avatar);
        }
      }
    }, [_vm._v(" Archive ")]), _c('a', {
      staticClass: "text-underline action",
      staticStyle: {
        "cursor": "pointer",
        "color": "#5e72e4"
      },
      on: {
        "click": function click($event) {
          return _vm.remove(avatar.id);
        }
      }
    }, [_vm._v("Remove")])], 1);
  }), 0)])], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-5 mx-3",
    staticStyle: {
      "border-bottom": "2px solid #d3d3d3"
    }
  })], 1), _c('b-row', [_c('b-col', [_c('h2', [_vm._v("Archived")]), _c('div', {
    staticClass: "d-flex flex-row flex-start flex-wrap my-3"
  }, _vm._l(_vm.inactiveAvatars, function (avatar) {
    return _c('div', {
      key: avatar.imageUrl,
      staticClass: "d-flex flex-column justify-content-center align-items-center mx-3 my-3"
    }, [_c('b-avatar', {
      attrs: {
        "src": avatar.imageUrl,
        "size": "8rem"
      }
    }), _c('h6', {
      staticClass: "my-2"
    }, [_vm._v(_vm._s(avatar.title))]), _c('a', {
      staticClass: "text-underline action my-2",
      staticStyle: {
        "cursor": "pointer",
        "color": "#5e72e4"
      },
      on: {
        "click": function click($event) {
          return _vm.restore(avatar);
        }
      }
    }, [_vm._v(" Restore ")]), _c('a', {
      staticClass: "text-underline action",
      staticStyle: {
        "cursor": "pointer",
        "color": "#5e72e4"
      },
      on: {
        "click": function click($event) {
          return _vm.remove(avatar.id);
        }
      }
    }, [_vm._v("Remove")])], 1);
  }), 0)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }