import { render, staticRenderFns } from "./OffersModal.vue?vue&type=template&id=5f22a324&scoped=true&"
import script from "./OffersModal.vue?vue&type=script&lang=ts&"
export * from "./OffersModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f22a324",
  null
  
)

export default component.exports