var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "header bg-gradient-info py-7 py-lg-8 pt-lg-9"
  }, [_c('b-container', [_c('div', {
    staticClass: "header-body text-center mb-7"
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    staticClass: "px-5",
    attrs: {
      "xl": "5",
      "lg": "6",
      "md": "6"
    }
  }, [_c('h1', {
    staticClass: "text-light"
  }, [_vm._v("Ooops!")]), _c('p', {
    staticClass: "text-lead text-white"
  }, [_vm._v(" Page not found. Don't worry though, we have plenty of other pages to explore ")]), _c('router-link', {
    staticClass: "font-weight-bold text-white mt-5",
    attrs: {
      "to": "/dashboard"
    }
  }, [_vm._v("Back to dashboard")])], 1)], 1)], 1)]), _c('div', {
    staticClass: "separator separator-bottom separator-skew zindex-100"
  }, [_c('svg', {
    attrs: {
      "x": "0",
      "y": "0",
      "viewBox": "0 0 2560 100",
      "preserveAspectRatio": "none",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('polygon', {
    staticClass: "fill-default",
    attrs: {
      "points": "2560 0 2560 100 0 100"
    }
  })])])], 1), _c('section', {
    staticClass: "py-6"
  }, [_c('b-container', [_c('b-row', {
    staticClass: "row-grid",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-img', {
    attrs: {
      "src": "",
      "fluid": ""
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "pr-md-5"
  }, [_c('h1', {
    staticClass: "text-light"
  }, [_vm._v("Explore pages")]), _c('p', {
    staticClass: "text-light"
  }, [_vm._v(" If you want to get inspiration or just show something directly to your clients, you can jump start your development with our pre-built example pages. ")]), _c('router-link', {
    staticClass: "font-weight-bold text-warning mt-5",
    attrs: {
      "to": "/user"
    }
  }, [_vm._v("Explore pages")])], 1)])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }