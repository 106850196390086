// boiletplate vue imports
import Vue from 'vue';
import App from './App.vue';
import Router from './router/routes';
// we use this plugin quite a bit throughout the app.
import * as VueGoogleMaps from 'vue2-google-maps';
import { ModalPlugin } from 'bootstrap-vue';
// argin dashboard plugin
import Dashboard from './plugins/DashboardPlugin';
// vuex as app state
import Vuex from 'vuex';
import store from './store';
// We use this somewhere?
import VueScrollTo from 'vue-scrollto';
// client API
import Client from './service/Client';
// firebase
import firebase from 'firebase/app';
import 'firebase/auth';
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_ID
};
firebase.initializeApp(firebaseConfig);
// Icons
import 'vue-awesome/icons';
import Icon from 'vue-awesome/components/Icon';
import { USER_ROLES } from '@gd-mono/gd-common';
import { UserClientImpl } from './service/UserClientImpl';
Vue.component('v-icon', Icon);
// now we tell vue to use what we imported
Vue.use(Vuex);
Vue.use(Dashboard);
Vue.config.devtools = true;
// You can also pass in the default options
Vue.use(VueScrollTo);
Vue.prototype.$events = new Vue();
Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
    installComponents: true,
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        libraries: 'places'
    }
});
Vue.use(ModalPlugin);
/**
 *
 This code here is the auth requirement for the application, if the meta requires auth and the user isn't logged in, they will be redirected to the login screen.
 *
 * */
Router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
    console.log('requiresAuth', requiresAuth);
    console.log('requiresAdmin', requiresAdmin);
    const user = await Client.getCurrentUser();
    if (requiresAuth && !user) {
        console.log('Sending to Login.');
        next('login');
    }
    else if (requiresAdmin) {
        // if we haven't loaded the user, fetch it
        if (!store.state.auth.user) {
            const portalUser = await UserClientImpl.getInstance().read(user.uid);
            // if not an admin
            if (portalUser.role !== USER_ROLES.ADMIN) {
                next('unauthorized');
            }
            else {
                next();
            }
        }
        else {
            if (store.state.auth.user.role !== USER_ROLES.ADMIN) {
                next('unauthorized');
            }
            else {
                next();
            }
        }
    }
    else {
        next();
    }
});
new Vue({
    router: Router,
    store,
    render: (h) => h(App)
}).$mount('#app');
