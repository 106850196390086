// built with the help of https://medium.com/swlh/properly-typed-vuex-stores-427bf4c6a3d1
import { getStoreBuilder } from 'vuex-typex';
/**
 * @description enum to ID what state the user is viewing the object, whether it be editing, viewing or creating
 * @author Logan Hendershot
 * @date 05/19/2022
 * @export
 * @enum {number}
 */
export var VIEW_STATE;
(function (VIEW_STATE) {
    VIEW_STATE["EDITING"] = "EDITING";
    VIEW_STATE["VIEWING"] = "VIEWING";
    VIEW_STATE["CREATING"] = "CREATING";
})(VIEW_STATE || (VIEW_STATE = {}));
export const storeBuilder = getStoreBuilder();
