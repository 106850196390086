import { storeBuilder } from "@/store/RootState";
import { TransactionClient } from "../../service/TransactionClient";
import { USER_ROLES } from "@gd-mono/gd-common";
import * as notificationStore from "./NotificationStore";
/**
 * @description Initial transaction state.
 *
 * Initialized to be either a Transaction object or undefined, depending on if they exist. This way it is declared in state but not defined.
 * @author Logan Hendershot
 * @date 06/23/2022
 * @export
 * @class TransactionState
 */
export class TransactionState {
    loading = false;
    transaction = undefined;
    allTransactions = undefined;
}
// Transaction module builder
const transactionsModule = storeBuilder.module("transactions", new TransactionState());
// Transaction client is the interface from the Transaction common code that has been implemented by this class TransactionClient, mainly defines read/write etc.
const transactionsClient = new TransactionClient();
// Getters
const getterFunctions = {
    getAllTransactions: transactionsModule.read((state) => state.allTransactions, "getAllTransactions"),
    getLoading: transactionsModule.read((state) => state.loading, "getLoading"),
};
export const getters = {
    get getAllTransactions() {
        return getterFunctions.getAllTransactions();
    },
    get getLoading() {
        return getterFunctions.getLoading();
    },
};
async function getAllTransactionsAction(context) {
    mutations.setLoading(true);
    try {
        if (context.rootState.auth.user.role === USER_ROLES.ADMIN) {
            const transactions = await transactionsClient.readAll();
            mutations.setAllTransaction(transactions);
        }
        else {
            const transactions = await transactionsClient.readByUserId(context.rootState.auth.user.id, null);
            mutations.setAllTransaction(transactions);
        }
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error getting transactions.",
            error,
        });
    }
    mutations.setLoading(false);
}
export const actions = {
    getAllTransactions: transactionsModule.dispatch(getAllTransactionsAction),
};
// Mutations
function setAllTransaction(state, transactions) {
    state.allTransactions = transactions;
}
function setLoading(state, payload) {
    state.loading = payload;
}
export const mutations = {
    setAllTransaction: transactionsModule.commit(setAllTransaction),
    setLoading: transactionsModule.commit(setLoading),
};
