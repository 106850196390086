/* eslint-disable @typescript-eslint/no-explicit-any */
import Client from "@/service/Client";
import { storeBuilder } from "@/store/RootState";
import * as notificationStore from "./NotificationStore";
export class AppState {
    position = {
        lat: 45.558007,
        lng: -122.63690765890699,
        dontAsk: false,
    };
}
// module builder
const appModule = storeBuilder.module("app", new AppState());
// getters declaration
const getterFunctions = {
    getPosition: appModule.read((state) => state.position, "getPosition"),
};
// getters export
export const getters = {
    get getPosition() {
        return getterFunctions.getPosition();
    },
};
// mutations declarations
function setPosition(state, position) {
    state.position.lat = position.lat;
    state.position.lng = position.lng;
    state.position = { ...state.position };
}
function setDontAsk(state, dontAsk) {
    state.position.dontAsk = dontAsk;
    state.position = { ...state.position };
}
// mutations export
export const mutations = {
    setPosition: appModule.commit(setPosition),
    setDontAsk: appModule.commit(setDontAsk),
};
async function uploadOfferImage(context, payload) {
    // mutations.setLoading(true)
    try {
        const resp = await Client.uploadImage(payload.file);
        // mutations.setLoading(false)
        return resp;
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Unable to Upload Image.",
            error,
        });
    }
    // mutations.setLoading(false)
}
export const actions = {
    uploadOfferImage: appModule.dispatch(uploadOfferImage),
};
