import Axios from 'axios';
import { REQUEST_METHOD } from '@gd-mono/gd-common';
import Client, { API_URL } from './Client';
import ENDPOINTS from '@/util/endpoints';
export class GiftCardClient {
    async create(giftCard) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.POST,
            url: API_URL + ENDPOINTS.GIFTCARDS,
            data: giftCard,
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
    async read(id) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + ENDPOINTS.GIFTCARDS + '/' + id,
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
    async update(giftCard) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.PUT,
            url: API_URL + ENDPOINTS.GIFTCARDS,
            data: giftCard,
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
    async delete(giftCardId) {
        const authToken = await Client.getAuthToken();
        await Axios({
            method: REQUEST_METHOD.DELETE,
            url: API_URL + ENDPOINTS.GIFTCARDS + '/' + giftCardId,
            headers: {
                authToken
            }
        });
    }
    async readAll() {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + ENDPOINTS.GIFTCARDS,
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
    async readAllByOwnerId(userId) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: API_URL + ENDPOINTS.GIFTCARDS + '?=userId=' + userId,
            headers: {
                authToken
            }
        });
        return resp.data.data;
    }
    readAllByCreatorId(userId, callingUser) {
        throw new Error('Method not implemented.');
    }
    readAllByAssignedId(id, callingUser) {
        throw new Error('Method not implemented.');
    }
}
