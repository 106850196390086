var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('bread-crumb', {
    attrs: {
      "list-classes": "breadcrumb-links breadcrumb-dark"
    }
  }, [_c('BreadCrumbItem', {
    staticClass: "d-flex align-items-center"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Dashboard'
      }
    }
  }, [_c('BIconHouseFill', {
    attrs: {
      "height": "22px",
      "width": "22px"
    }
  })], 1)], 1), _vm._l(_vm.filteredRoutes, function (route, index) {
    return _c('BreadCrumbItem', {
      key: route.name,
      staticStyle: {
        "display": "inline-block",
        "font-size": "20px"
      },
      attrs: {
        "active": index === _vm.$route.matched.length - 1
      }
    }, [index < _vm.$route.matched.length - 1 ? _c('router-link', {
      attrs: {
        "to": {
          name: route.name
        }
      }
    }, [_vm._v(" " + _vm._s(route.name) + " ")]) : _c('span', [_vm._v(_vm._s(route.name))])], 1);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }