var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notifications"
  }, [_c('slide-y-up-transition', {
    attrs: {
      "duration": _vm.transitionDuration,
      "group": "",
      "mode": "out-in"
    }
  }, _vm._l(_vm.notifications, function (notification) {
    return _c('Notification', _vm._b({
      key: notification.timestamp,
      attrs: {
        "clickHandler": notification.onClick
      },
      on: {
        "close": _vm.removeNotification
      }
    }, 'Notification', notification, false));
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }