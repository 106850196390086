<template>
    <!-- <div class="tags-input__wrapper"> -->
    <div>
        <label class="form-control-label" for="tags">{{ label }}</label>
        <div class="my-2" v-if="dynamicTags.length">
            <el-tag
                style="text-transform: capitalize"
                v-for="(tag, index) in dynamicTags"
                :key="tag + index"
                size="small"
                :type="tagType"
                :closable="true"
                :close-transition="false"
                @close="handleClose(tag)">
                {{ tag }}
            </el-tag>
        </div>

        <input
            name="tags"
            class="form-control mb-4"
            v-model="inputValue"
            ref="saveTagInput"
            size="mini"
            v-bind="$attrs"
            @input="onInput"
            @keydown.enter.prevent=""
            @keyup.enter.prevent="handleInputConfirm"
            @blur="handleInputConfirm" />
    </div>
</template>

<script>
import { Tag } from 'element-ui'

export default {
    name: 'tags-input',
    inheritAttrs: false,
    components: {
        [Tag.name]: Tag
    },
    props: {
        value: {
            type: Array,
            default: () => [],
            description: 'List of tags'
        },
        tagType: {
            type: String,
            default: 'primary',
            description: 'Tag type (primary|danger etc)'
        },
        label: {
            type: String,
            default: 'Tags',
            description: 'Label for the input'
        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            dynamicTags: [],
            inputVisible: false,
            inputValue: ''
        }
    },
    methods: {
        handleClose(tag) {
            this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
            this.$emit('change', this.dynamicTags)
        },
        showInput() {
            this.inputVisible = true
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus()
            })
        },
        handleInputConfirm() {
            let inputValue = this.inputValue.toLowerCase()
            if (inputValue) {
                this.dynamicTags.push(inputValue)
                this.$emit('change', this.dynamicTags)
            }
            this.inputVisible = false
            this.inputValue = ''
        },
        onInput(evt) {
            this.$emit('input', evt.target.value)
        }
    },
    created() {
        this.$watch(
            'value',
            (newVal) => {
                this.dynamicTags = [...newVal]
            },
            { immediate: true }
        )
    }
}
</script>
