var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    staticClass: "py-3 h-100",
    attrs: {
      "id": "whats-giftdrop"
    }
  }, [_c('b-row', {
    staticClass: "h-100"
  }, [_c('b-col', {
    staticClass: "d-none d-lg-flex justify-content-conter align-items-center h-100 pl-0",
    attrs: {
      "sm": "0",
      "lg": "5"
    }
  }, [_c('img', {
    staticClass: "img-fluid how-it-works-screen",
    attrs: {
      "src": require("@/assets/img/whats-giftdrop.png"),
      "alt": "giftdrop preview"
    }
  })]), _c('b-col', {
    staticClass: "d-flex flex-column justify-content-center text-white",
    attrs: {
      "sm": "12",
      "lg": "7"
    }
  }, [_c('div', [_c('h1', {
    staticClass: "display-1 d-inline-block text-white ml-4"
  }, [_vm._v(" What's "), _c('span', {
    staticClass: "text-yellow display-1"
  }, [_vm._v("GiftDrop®️?")])])]), _c('div', {
    staticClass: "custom-card"
  }, [_c('div', {
    staticClass: "d-none d-md-flex justify-content-center align-items-center h-100"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/img/globe.png"),
      "alt": "globe",
      "width": "62px",
      "height": "62px"
    }
  })]), _c('div', [_c('h1', {
    staticClass: "display-3 text-yellow"
  }, [_vm._v("First, pick up a drop")]), _c('p', {
    staticClass: "lead"
  }, [_vm._v(" A drop can contain a deal for a free item, giftcard and more! Make sure you are near a drop to pick it up. ")])])]), _c('div', {
    staticClass: "custom-card"
  }, [_c('div', {
    staticClass: "d-none d-md-flex justify-content-center align-items-center"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/img/present.png"),
      "alt": "present",
      "width": "62px",
      "height": "62px"
    }
  })]), _c('div', [_c('h1', {
    staticClass: "display-3 text-yellow"
  }, [_vm._v("Next, redeem the drop")]), _c('p', {
    staticClass: "lead"
  }, [_vm._v(" Once a drop has been picked up and is your wallet, the next step is to go to the redemption location and redeem the drop with the merchant. ")])])]), _c('div', {
    staticClass: "custom-card"
  }, [_c('div', {
    staticClass: "d-none d-md-flex justify-content-center align-items-center"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/img/discount.png"),
      "alt": "discount",
      "width": "62px",
      "height": "62px"
    }
  })]), _c('div', [_c('h1', {
    staticClass: "display-3 text-yellow"
  }, [_vm._v("Enjoy!")]), _c('p', {
    staticClass: "lead"
  }, [_vm._v(" Once you have redeemed your drop with the merchant, find more drops! ")])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }