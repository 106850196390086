/* eslint-disable */
import moment from "moment";
import { Campaign, } from "@gd-mono/gd-common";
import CampaignClient from "../../service/CampaignClient";
import { storeBuilder, VIEW_STATE } from "@/store/RootState";
import * as notificationStore from "./NotificationStore";
// default offer image
//"https://res.cloudinary.com/giftdrop-mobile/image/upload/v1658872000/rqbdkqo1cddo9u97h4ko.png"
// default video
// https://www.youtube.com/watch?v=zLjj9DLCArY
/**
 * @description Base store state for handling campaigns
 * @author Alejandro Nieto
 * @date 4/20/2022
 * @export
 * @class CampaignState
 */
export class CampaignState {
    loading = false;
    viewState = VIEW_STATE.VIEWING;
    campaign = undefined;
    allCampaigns = new Array();
    campaignInProgress;
    campaignProgress = 0;
    editingCampaign = undefined;
    giftCardIdsToAdd;
    purchaseToken;
    targetCampaignAnalytics;
}
const campaignModule = storeBuilder.module("campaign", new CampaignState());
// Getters
const getterFunctions = {
    getCampaign: campaignModule.read((state) => state.campaign, "getCampaign"),
    allCampaigns: campaignModule.read((state) => state.allCampaigns, "allCampaigns"),
    viewState: campaignModule.read((state) => state.viewState, "viewState"),
    allCampaignsUnarchived: campaignModule.read((state) => state.allCampaigns?.filter((campaign) => !campaign.archived), "allCampaignsUnarchived"),
    getCampaignById: campaignModule.read((state) => (id) => state.allCampaigns.find((campaign) => campaign.id === id), "getCampaignById"),
    getCampaignStartDateReadable: campaignModule.read((state) => moment(state.campaign.dateStart).format("MM-DD hh:mm A"), "getCampaignStartDateReadable"),
    getCampaignEndDateReadable: campaignModule.read((state) => moment(state.campaign?.dateEnd).format("MM-DD hh:mm A"), "getCampaignEndDateReadable"),
    getCampaignDurationReadable: campaignModule.read((state) => {
        const startDate = moment(state.campaign?.dateStart);
        const endDate = moment(state.campaign?.dateEnd);
        const duration = moment.duration(endDate.diff(startDate));
        const days = duration.asDays();
        const hours = duration.asHours();
        const daysDisplay = Math.floor(days);
        const hoursDisplay = Math.floor(hours - daysDisplay * 24);
        return `${daysDisplay} days ${hoursDisplay} hours`;
    }, "getCampaignDurationReadable"),
    getComputedStatus: campaignModule.read(() => (campaign) => {
        const now = new Date().getTime();
        const status = {
            status: "",
            statusType: "",
        };
        if (campaign.startDateInMs >= now) {
            status.status = "waiting...";
            status.statusType = "info";
        }
        if (campaign.blocked) {
            status.status = "locked";
            status.statusType = "danger";
        }
        if (campaign.expirationInMs <= now) {
            status.status = "expired";
            status.statusType = "muted";
        }
        if (campaign.paused === true) {
            status.status = "paused";
            status.statusType = "warning";
        }
        if (campaign.archived === true) {
            status.status = "archived";
            status.statusType = "muted";
        }
        // if (
        //     (campaign.numRedeemed ? campaign.numRedeemed : 0) -
        //         campaign.redeem.totalRedemptions ===
        //     0
        // ) {
        //     status.status = 'complete'
        //     status.statusType = 'info'
        // }
        if (status.status === "") {
            status.status = "live";
            status.statusType = "success";
        }
        return status;
    }, "getComputedStatus"),
    getComputedDropType: campaignModule.read(() => (campaign) => {
        const dropTypeString = "";
        // if (campaign.dropType === 'giftCard') {
        //     dropTypeString = 'Gift Card'
        // } else {
        //     if (campaign.discount.discountType === 'all') {
        //         dropTypeString = 'Discount - All Purchases'
        //     } else if (campaign.discount.discountType === 'single') {
        //         dropTypeString = 'Discount - On Item'
        //     } else if (campaign.discount.discountType === 'bogo') {
        //         dropTypeString = 'Discount - BOGO'
        //     } else {
        //         dropTypeString = 'Discount - Gift'
        //     }
        // }
        return dropTypeString;
    }, "getComputedDropType"),
    giftCardIdsToAdd: campaignModule.read((state) => state.giftCardIdsToAdd, "giftCardIdsToAdd"),
    getEditingCampaign: campaignModule.read((state) => state.editingCampaign, "getEditingCampaign"),
    isEditing: campaignModule.read((state) => state.editingCampaign !== null, "isEditing"),
    isCampaignInProgress: campaignModule.read((state) => state.campaignInProgress === true, "isCampaignInProgress"),
    getCampaignProgress: campaignModule.read((state) => state.campaignProgress, "getCampaignProgress"),
    campaignDateStart: campaignModule.read((state) => state.campaign.startDateInMs, "campaignDateStart"),
    campaignExpirationInMs: campaignModule.read((state) => state.campaign.expirationInMs, "campaignExpirationInMs"),
    getCampaignDateStartFormatted: campaignModule.read((state) => moment(state.campaign.startDateInMs).format("YYYY-MM-DDTkk:mm"), "getCampaignDateStartFormatted"),
    getCampaignDateEndFormatted: campaignModule.read((state) => moment(state.campaign.expirationInMs).format("YYYY-MM-DDTkk:mm"), "getCampaignDateEndFormatted"),
    getCampaignCompany: campaignModule.read((state) => state.campaign?.company, "getCampaignCompany"),
    getCampaignCompanyId: campaignModule.read((state) => state.campaign?.companyId, "getCampaignCompanyId"),
    getCampaignCompanyLogoUrl: campaignModule.read((state) => state.campaign?.offer?.logo_url, "getCampaignCompanyLogoUrl"),
    getPurchaseToken: campaignModule.read((state) => state.purchaseToken, "getPurchaseToken"),
    getLoading: campaignModule.read((state) => state.loading, "getLoading"),
};
export const getters = {
    get getCampaign() {
        return getterFunctions.getCampaign();
    },
    get allCampaigns() {
        return getterFunctions.allCampaigns();
    },
    get viewState() {
        return getterFunctions.viewState();
    },
    get allCampaignsUnarchived() {
        return getterFunctions.allCampaignsUnarchived();
    },
    get campaignById() {
        return getterFunctions.getCampaignById();
    },
    get giftCardIdsToAdd() {
        return getterFunctions.giftCardIdsToAdd();
    },
    get getEditingCampaign() {
        return getterFunctions.getEditingCampaign();
    },
    get isEditing() {
        return getterFunctions.isEditing();
    },
    get isCampaignInProgress() {
        return getterFunctions.isCampaignInProgress();
    },
    get getCampaignProgress() {
        return getterFunctions.getCampaignProgress();
    },
    get campaignDateStart() {
        return getterFunctions.campaignDateStart();
    },
    get campaignExpirationInMs() {
        return getterFunctions.campaignExpirationInMs();
    },
    get getPurchaseToken() {
        return getterFunctions.getPurchaseToken();
    },
    get getLoading() {
        return getterFunctions.getLoading();
    },
    get campaignDateStartFormatted() {
        return getterFunctions.getCampaignDateStartFormatted();
    },
    get campaignDateEndFormatted() {
        return getterFunctions.getCampaignDateEndFormatted();
    },
    get getComputedStatus() {
        return getterFunctions.getComputedStatus();
    },
    get getComputedDropType() {
        return getterFunctions.getComputedDropType();
    },
    get getCampaignCompany() {
        return getterFunctions.getCampaignCompany();
    },
    get getCampaignCompanyId() {
        return getterFunctions.getCampaignCompanyId();
    },
    get getCampaignCompanyLogoUrl() {
        return getterFunctions.getCampaignCompanyLogoUrl();
    },
    get getCampaignStartDateReadable() {
        return getterFunctions.getCampaignStartDateReadable();
    },
    get getCampaignEndDateReadable() {
        return getterFunctions.getCampaignEndDateReadable();
    },
    get getCampaignDurationReadable() {
        return getterFunctions.getCampaignDurationReadable();
    },
};
/**
 * can include context: ActionContext as the first argument if you need root state
 *
 * @param {ActionContext} context
 */
const actionFunctions = {
    async getAllCampaigns() {
        mutations.setLoading(true);
        try {
            const campaigns = await CampaignClient.getCampaigns();
            // reverse to order by last created
            mutations.setCampaigns(campaigns.reverse());
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error getting campaigns.",
                error,
            });
        }
        mutations.setLoading(false);
    },
    async getCampaign(context, id) {
        mutations.setLoading(true);
        try {
            const campaign = await CampaignClient.getCampaign(id);
            mutations.setCampaign(campaign.data.data);
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error getting campaign.",
                error,
            });
        }
        mutations.setLoading(false);
    },
    async archiveCampaign(_, campaign) {
        mutations.setLoading(true);
        try {
            await CampaignClient.deleteCampaign(campaign.id, true);
            notificationStore.actions.pushSuccess({
                title: "Campaign Archived.",
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error Archiving Campaign.",
                error,
            });
        }
        mutations.setLoading(false);
    },
    async pauseCampaign(_, campaign) {
        mutations.setLoading(true);
        try {
            await CampaignClient.pauseCampaign(campaign.id, !campaign.paused);
            notificationStore.actions.pushSuccess({
                title: "Campaign paused.",
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error pausing campaign.",
                error,
            });
        }
        mutations.setLoading(false);
    },
    async blockCampaign(_, payload) {
        mutations.setLoading(true);
        try {
            await CampaignClient.lockCampaign(payload.campaignId, payload.block);
            notificationStore.actions.pushSuccess({
                title: "Campaign blocked.",
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error blocking campaign.",
                error,
            });
        }
        mutations.setLoading(false);
    },
    async updateCampaign(context) {
        mutations.setLoading(true);
        try {
            const resp = await CampaignClient.updateCampaign(context.state.editingCampaign.id, context.state.editingCampaign);
            mutations.setCampaign(resp.data.data);
            notificationStore.actions.pushSuccess({
                title: "Campaign updated.",
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error updating campaign.",
                error,
            });
        }
        finally {
            mutations.setEditingCampaign(undefined);
            mutations.setViewState(VIEW_STATE.VIEWING);
            mutations.setLoading(false);
        }
    },
    async updateCampaignRedemptionLocations(context, redemptionLocations) {
        mutations.setLoading(true);
        try {
            const editedCampaign = Object.assign({}, context.state.editingCampaign);
            editedCampaign.redemptionType = redemptionLocations.redemptionType;
            editedCampaign.redemptionLocations =
                redemptionLocations.redemptionLocations;
            const resp = await CampaignClient.updateCampaign(editedCampaign.id, editedCampaign);
            mutations.setCampaign(resp.data.data);
            notificationStore.actions.pushSuccess({
                title: "Redemption locations updated.",
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error updating campaign redemption locations.",
                error,
            });
        }
        mutations.setLoading(false);
    },
    async createCampaign(context) {
        mutations.setLoading(true);
        try {
            const resp = await CampaignClient.createCampaign(context.state.campaign);
            // mutations.clearCampaign()
            notificationStore.actions.pushSuccess({
                title: "Campaign Created!.",
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error Creating Campaign.",
                error,
            });
            mutations.setLoading(false);
            throw error;
        }
        mutations.setLoading(false);
    },
};
export const actions = {
    getAllCampaigns: campaignModule.dispatch(actionFunctions.getAllCampaigns),
    getCampaign: campaignModule.dispatch(actionFunctions.getCampaign),
    archiveCampaign: campaignModule.dispatch(actionFunctions.archiveCampaign),
    pauseCampaign: campaignModule.dispatch(actionFunctions.pauseCampaign),
    blockCampaign: campaignModule.dispatch(actionFunctions.blockCampaign),
    updateCampaign: campaignModule.dispatch(actionFunctions.updateCampaign),
    updateCampaignRedemptionLocations: campaignModule.dispatch(actionFunctions.updateCampaignRedemptionLocations),
    createCampaign: campaignModule.dispatch(actionFunctions.createCampaign),
};
// Mutations
// CampaignOffer inventory
function setCampaignOfferInventory(state, payload) {
    // if payload null, set sourceMeta to null
    state.campaign.offer.product_ids = payload;
    state.campaign.offer.quantity = payload.length;
    state.campaign = { ...state.campaign };
}
function pushCampaignOfferInventory(state, payload) {
    state.campaign.offer.product_ids ??= [];
    state.campaign.offer.product_ids.push(payload);
    state.campaign = { ...state.campaign };
}
function setCampaignOfferBarcodeFormat(state, payload) {
    state.campaign.offer.barcode_type = payload;
    state.campaign = { ...state.campaign };
}
function clearCampaignOfferInventory(state) {
    state.campaign.offer.product_ids ??= [];
    state.campaign = { ...state.campaign };
}
function setCampaigns(state, campaigns) {
    state.allCampaigns = campaigns;
}
function setCampaignInProgress(state, payload) {
    state.campaignInProgress = payload;
}
function setGiftCardIdsToAdd(state, payload) {
    state.giftCardIdsToAdd = payload;
}
function setEditingCampaign(state, campaign) {
    state.editingCampaign = JSON.parse(JSON.stringify(campaign)); // deep copy
}
function clearCampaign(state) {
    state.campaign = undefined;
    state.campaignInProgress = false;
    state.campaignProgress = 0;
    state.giftCardIdsToAdd = [];
    state.purchaseToken = null;
}
function updateName(state, payload) {
    state.campaign.name = payload;
    state.campaign = { ...state.campaign };
}
function incrementNewCampaignProgress(state, payload) {
    state.campaignProgress += payload;
}
function updateCampaignStartDate(state, payload) {
    state.campaign.startDateInMs = moment(payload).valueOf();
}
function updateCampaignDateStart(state, payload) {
    state.campaign.dateStart = payload;
    state.campaign = { ...state.campaign };
}
function updateCampaignDateEnd(state, payload) {
    state.campaign.dateEnd = payload;
    state.campaign = { ...state.campaign };
}
function updateCampaignEndDate(state, payload) {
    state.campaign.expirationInMs = moment(payload).valueOf();
}
function updateRedemptionLocations(state, payload) {
    state.campaign.redemptionLocations = payload;
}
function spliceRedemptionLocations(state, index) {
    state.campaign.redemptionLocations.splice(index, 1);
}
function addRedemptionLocations(state, payload) {
    state.campaign.redemptionLocations.push(payload);
}
function updateCampaignRedemptionType(state, payload) {
    state.campaign.redemptionType = payload;
}
// function updateCampaignRedemptionMessage(state: CampaignState, payload: string): void {
//     state.campaign.redeem.message = payload
// }
// function updateCampaignBarcode(state: CampaignState, payload: string): void {
//     state.campaign.redeem.upc = payload
// }
function updateCampaignPins(state, payload) {
    state.campaign.pins = payload;
}
function updateCampaignOrEditingCampaignPins(state, payload) {
    // if (state.editingCampaign && state.viewState === VIEW_STATE.EDITING) {
    //     state.editingCampaign.pins = payload
    // } else {
    state.campaign.pins = payload;
    // }
}
function addCampaignPin(state, payload) {
    state.campaign.pins.push(payload);
}
function addCampaignOrEditingCampaignPins(state, payload) {
    if (state.editingCampaign && state.viewState === VIEW_STATE.EDITING) {
        state.editingCampaign.pins.push(payload);
    }
    else {
        state.campaign.pins.push(payload);
    }
}
function spliceCampaignPins(state, index) {
    state.campaign.pins.splice(index, 1);
}
function updateCampaignPinPosition(state, pin) {
    const index = state.campaign.pins.findIndex((i) => i.id === pin.id);
    state.campaign.pins[index] = pin;
    state.campaign = { ...state.campaign };
    state.campaign = { ...state.campaign };
}
function setCreationPurchaseToken(state, payload) {
    state.purchaseToken = payload;
}
function setViewState(state, payload) {
    state.viewState = payload;
}
function setCampaign(state, payload) {
    state.campaign = payload;
}
function setCampaignCompany(state, payload) {
    state.campaign.company = payload;
}
function setCampaignCompanyId(state, payload) {
    state.campaign.companyId = payload;
}
function setLoading(state, payload) {
    state.loading = payload;
}
function initializeNewCampaign(state) {
    state.campaign = new Campaign();
    state.campaignProgress = 0;
}
function setOffer(state, payload) {
    state.campaign.offer = payload;
    state.campaign = { ...state.campaign };
}
function setCampaignOfferGCInventory(state, payload) {
    state.campaign.offer.quantity = payload.ids.length;
    state.campaign.offer.product_ids = payload.ids;
    state.campaign.offer.metadata ??= {};
    state.campaign.offer.metadata.value = payload.value;
    state.campaign.offer.metadata.value_string = `$${payload.value}`;
    state.campaign.offer.metadata.value_type = "DOLLAR";
    state.campaign = { ...state.campaign };
}
export const mutations = {
    setCampaigns: campaignModule.commit(setCampaigns),
    setCampaign: campaignModule.commit(setCampaign),
    setCampaignInProgress: campaignModule.commit(setCampaignInProgress),
    setGiftCardIdsToAdd: campaignModule.commit(setGiftCardIdsToAdd),
    setEditingCampaign: campaignModule.commit(setEditingCampaign),
    clearCampaign: campaignModule.commit(clearCampaign),
    updateName: campaignModule.commit(updateName),
    // CAMPAIGN OFFERS
    setCampaignOfferInventory: campaignModule.commit(setCampaignOfferInventory),
    pushCampaignOfferInventory: campaignModule.commit(pushCampaignOfferInventory),
    setCampaignOfferBarcodeFormat: campaignModule.commit(setCampaignOfferBarcodeFormat),
    clearCampaignOfferInventory: campaignModule.commit(clearCampaignOfferInventory),
    setCampaignOfferGCInventory: campaignModule.commit(setCampaignOfferGCInventory),
    // updateOfferName: campaignModule.commit(updateOfferName),
    // updateDescription: campaignModule.commit(updateDescription),
    // updateOfferUrl: campaignModule.commit(updateOfferUrl),
    // updateImageUrls: campaignModule.commit(updateImageUrls),
    // addImageUrls: campaignModule.commit(addImageUrls),
    // spliceImageUrls: campaignModule.commit(spliceImageUrls),
    // updateVideoUrl: campaignModule.commit(updateVideoUrl),
    incrementNewCampaignProgress: campaignModule.commit(incrementNewCampaignProgress),
    // updateDropType: campaignModule.commit(updateDropType),
    // updateItemOfferDescription: campaignModule.commit(updateItemOfferDescription),
    // updateCampaignDiscountType: campaignModule.commit(updateCampaignDiscountType),
    // updateCampaignDiscountPercent: campaignModule.commit(updateCampaignDiscountPercent),
    // updateCampaignDiscountRetailValue: campaignModule.commit(updateCampaignDiscountRetailValue),
    // updateCampaignTotalRedemptions: campaignModule.commit(updateCampaignTotalRedemptions),
    updateCampaignStartDate: campaignModule.commit(updateCampaignStartDate),
    updateCampaignDateStart: campaignModule.commit(updateCampaignDateStart),
    updateCampaignDateEnd: campaignModule.commit(updateCampaignDateEnd),
    updateCampaignEndDate: campaignModule.commit(updateCampaignEndDate),
    updateRedemptionLocations: campaignModule.commit(updateRedemptionLocations),
    spliceRedemptionLocations: campaignModule.commit(spliceRedemptionLocations),
    addRedemptionLocations: campaignModule.commit(addRedemptionLocations),
    updateCampaignRedemptionType: campaignModule.commit(updateCampaignRedemptionType),
    // updateCampaignRedemptionMessage: campaignModule.commit(updateCampaignRedemptionMessage),
    // updateCampaignBarcode: campaignModule.commit(updateCampaignBarcode),
    updateCampaignPins: campaignModule.commit(updateCampaignPins),
    addCampaignPin: campaignModule.commit(addCampaignPin),
    spliceCampaignPins: campaignModule.commit(spliceCampaignPins),
    updateCampaignPinPosition: campaignModule.commit(updateCampaignPinPosition),
    setCreationPurchaseToken: campaignModule.commit(setCreationPurchaseToken),
    setViewState: campaignModule.commit(setViewState),
    setLoading: campaignModule.commit(setLoading),
    initializeNewCampaign: campaignModule.commit(initializeNewCampaign),
    setCampaignCompany: campaignModule.commit(setCampaignCompany),
    setCampaignCompanyId: campaignModule.commit(setCampaignCompanyId),
    updateCampaignOrEditingCampaignPins: campaignModule.commit(updateCampaignOrEditingCampaignPins),
    addCampaignOrEditingCampaignPins: campaignModule.commit(addCampaignOrEditingCampaignPins),
    setOffer: campaignModule.commit(setOffer),
};
