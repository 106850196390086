var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_c('div', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('b-card-title', {
    staticClass: "display-2 h2 mb-0",
    attrs: {
      "title-tag": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.isAdmin ? 'Choose a company for campaign creation' : 'Create your Company Profile') + " ")]), _c('b-card-text', {
    staticClass: "mt-4"
  }, [_vm._v(" GiftDrop requires a company to use the Platform. Create your company to get started! ")]), _c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-button', {
    staticClass: "ml-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/portal/companies/new');
      }
    }
  }, [_vm._v(" Create Company Profile ")])], 1)], 1), _c('b-col', [_c('b-img', {
    attrs: {
      "src": "/img/backgrounds/no-company-search.svg",
      "fluid": "",
      "alt": "Find Company"
    }
  })], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "size": "xl",
      "busy": "",
      "hide-header": "",
      "hide-footer": "",
      "body-class": "p-0"
    },
    model: {
      value: _vm.showFind,
      callback: function callback($$v) {
        _vm.showFind = $$v;
      },
      expression: "showFind"
    }
  }, [_c('CompanyListTable', {
    on: {
      "select": _vm.selectCompany
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }