var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "my-5",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "mb-5"
  }, [_c('h1', {
    staticClass: "display-3 mt-0"
  }, [_vm._v("Where will these Gift Cards come from?")]), _c('p', {
    staticClass: "lead text-muted"
  }, [_vm._v(" You can upload your own Gift Card inventory, or choose from our catalog. ")])]), _c('b-card-group', {
    attrs: {
      "deck": ""
    }
  }, [_c('OffersCardComponent', {
    attrs: {
      "title": "Use The GiftDrop Catalog",
      "description": "You can select from our catalog of Gift Cards which will automatically populate the drop with gift cards.",
      "imgSrc": "https://via.placeholder.com/300x200?Text=Placeholder",
      "selected": _vm.selectedSource === null ? null : _vm.selectedSource === 'PROVIDER'
    },
    on: {
      "onSelect": function onSelect($event) {
        return _vm.selectionChange({
          source: 'PROVIDER',
          type: 'GIFTCARD_PROVIDER'
        });
      }
    }
  }), _c('OffersCardComponent', {
    attrs: {
      "title": "Uploaded or Created Gift Cards",
      "description": "This drop will draw from Gift Card inventory you create and assign to it.",
      "imgSrc": "https://via.placeholder.com/300x200?Text=Placeholder",
      "selected": _vm.selectedSource === null ? null : _vm.selectedSource === 'INVENTORY'
    },
    on: {
      "onSelect": function onSelect($event) {
        return _vm.selectionChange({
          source: 'INVENTORY',
          type: 'GIFTCARD_INVENTORY'
        });
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }