import Axios from "axios";
import { REQUEST_METHOD, COMPANIES_PATCH_ACTION, } from "@gd-mono/gd-common";
import Client, { API_URL } from "./Client";
export class CompanyClient {
    async create(creatorUserId, company) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.POST,
            url: `${API_URL}/companies`,
            headers: {
                authToken,
            },
            data: {
                company,
                uid: creatorUserId,
            },
        });
        return resp.data.data;
    }
    async read(id) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: `${API_URL}/companies/${id}`,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
    async readAll() {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: `${API_URL}/companies`,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
    async update(company) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.PUT,
            url: `${API_URL}/companies/${company.id}`,
            headers: {
                authToken,
            },
            data: company,
        });
        return resp.data.data;
    }
    async addMember(companyId, targetUserId) {
        const authToken = await Client.getAuthToken();
        // define payload
        const payload = {
            action: COMPANIES_PATCH_ACTION.ADD_MEMBER,
            userId: targetUserId,
        };
        // create request
        const resp = await Axios({
            method: REQUEST_METHOD.PATCH,
            url: `${API_URL}/companies/${companyId}`,
            headers: {
                authToken,
            },
            data: payload,
        });
        return resp.data.data;
    }
    async removeMember(companyId, targetUserId) {
        const authToken = await Client.getAuthToken();
        // define payload
        const payload = {
            action: COMPANIES_PATCH_ACTION.REMOVE_MEMBER,
            userId: targetUserId,
        };
        // create request
        const resp = await Axios({
            method: REQUEST_METHOD.PATCH,
            url: `${API_URL}/companies/${companyId}`,
            headers: {
                authToken,
            },
            data: payload,
        });
        return resp.data.data;
    }
    async updateOwner(companyId, targetUserId) {
        const authToken = await Client.getAuthToken();
        // define payload
        const payload = {
            action: COMPANIES_PATCH_ACTION.UPDATE_OWNER,
            userId: targetUserId,
        };
        // create request
        const resp = await Axios({
            method: REQUEST_METHOD.PATCH,
            url: `${API_URL}/companies/${companyId}`,
            headers: {
                authToken,
            },
            data: payload,
        });
        return resp.data.data;
    }
    async updateApproval(companyId, approval) {
        const authToken = await Client.getAuthToken();
        // define payload
        const payload = {
            action: approval
                ? COMPANIES_PATCH_ACTION.APPROVE
                : COMPANIES_PATCH_ACTION.UNAPPROVE,
        };
        // create request
        const resp = await Axios({
            method: REQUEST_METHOD.PATCH,
            url: `${API_URL}/companies/${companyId}`,
            headers: {
                authToken,
            },
            data: payload,
        });
        return resp.data.data;
    }
    async requestToJoin(companyId) {
        const authToken = await Client.getAuthToken();
        // define payload
        const payload = {
            action: COMPANIES_PATCH_ACTION.REQUEST_JOIN,
        };
        // create request
        const resp = await Axios({
            method: REQUEST_METHOD.PATCH,
            url: `${API_URL}/companies/${companyId}`,
            headers: {
                authToken,
            },
            data: payload,
        });
        return resp.data.data;
    }
    async acceptJoinRequest(companyId, userId) {
        const authToken = await Client.getAuthToken();
        // define payload
        const payload = {
            action: COMPANIES_PATCH_ACTION.ACCEPT_JOIN,
            userId,
        };
        // create request
        const resp = await Axios({
            method: REQUEST_METHOD.PATCH,
            url: `${API_URL}/companies/${companyId}`,
            headers: {
                authToken,
            },
            data: payload,
        });
        return resp.data.data;
    }
    async readByFilter(filter) {
        const authToken = await Client.getAuthToken();
        // create request
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: `${API_URL}/companies?filter=${filter}`,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
    readByUserId(userId) {
        throw new Error("Method not implemented.");
    }
    delete(companyId, callingUser) {
        throw new Error("Method not implemented.");
    }
}
