var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "basic-modal"
  }, [_c('div', {
    staticClass: "background-overlay",
    on: {
      "click": _vm.cancel
    }
  }), _c('div', {
    staticClass: "box"
  }, [_vm._l(_vm.basicModal.title.split('\n'), function (line) {
    return _c('h5', {
      key: line,
      staticClass: "title"
    }, [_vm._v(" " + _vm._s(line) + " ")]);
  }), _c('h5', {
    staticClass: "subtitle"
  }, [_vm._v(_vm._s(_vm.basicModal.text))]), _vm.basicModal.mode === 'confirm' ? _c('div', {
    staticClass: "w-100 d-flex justify-content-between buttons px-5"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" " + _vm._s(_vm.basicModal.cancelText) + " ")]), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v(" " + _vm._s(_vm.basicModal.confirmText) + " ")])]) : _vm._e(), _vm.basicModal.mode === 'ok' ? _c('div', {
    staticClass: "buttons"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v(" " + _vm._s(_vm.basicModal.confirmText) + " ")])]) : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }