/* eslint-disable @typescript-eslint/no-explicit-any */
import { storeBuilder } from '@/store/RootState';
export const NotificationDefaults = {
    overlap: false,
    verticalAlign: 'top',
    horizontalAlign: 'right',
    type: 'info',
    timeout: 5000,
    closeOnClick: true,
    showClose: true
};
export class NotificationState {
    notifications = new Array();
}
// module builder
const notificationModule = storeBuilder.module('notification', new NotificationState());
// getters declaration
const getterFunctions = {
    getNotifications: notificationModule.read((state) => state.notifications, 'getNotifications')
};
// getters export
export const getters = {
    get getNotifications() {
        return getterFunctions.getNotifications();
    }
};
// mutations declarations
function pushNotificationMutation(state, notification) {
    notification.timestamp = new Date().getTime();
    notification = Object.assign({}, NotificationDefaults, notification);
    state.notifications.push(notification);
}
function popNotificationMutation(state) {
    state.notifications.pop();
}
function removeNotificationMutation(state, timestamp) {
    const indexToDelete = state.notifications.findIndex((n) => n.timestamp === timestamp);
    if (indexToDelete !== -1) {
        state.notifications.splice(indexToDelete, 1);
    }
}
// mutations export
export const mutations = {
    pushNotificationMutation: notificationModule.commit(pushNotificationMutation),
    popNotificationMutation: notificationModule.commit(popNotificationMutation),
    removeNotificationMutation: notificationModule.commit(removeNotificationMutation)
};
async function pushNotificationAction(context, notification) {
    mutations.pushNotificationMutation(notification);
}
async function pushSuccess(context, notification) {
    notification.type = 'success';
    mutations.pushNotificationMutation(notification);
}
async function pushError(context, notification) {
    notification.type = 'warning';
    if (notification.error?.response?.data?.error) {
        notification.message = notification.error.response.data.error;
    }
    else if (notification.error) {
        notification.message = notification.error.message;
    }
    mutations.pushNotificationMutation(notification);
}
async function popNotificationAction() {
    mutations.popNotificationMutation();
}
async function removeNotificationAction(context, timestamp) {
    mutations.removeNotificationMutation(timestamp);
}
// actions export
export const actions = {
    pushNotificationAction: notificationModule.dispatch(pushNotificationAction),
    pushSuccess: notificationModule.dispatch(pushSuccess),
    pushError: notificationModule.dispatch(pushError),
    popNotificationAction: notificationModule.dispatch(popNotificationAction),
    removeNotificationAction: notificationModule.dispatch(removeNotificationAction)
};
