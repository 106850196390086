var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer py-5"
  }, [_c('b-row', {
    staticClass: "justify-content-lg-between",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "copyright text-center text-lg-left text-muted"
  }, [_c('router-link', {
    staticClass: "font-weight-bold ml-1",
    attrs: {
      "to": {
        name: 'Dashboard'
      }
    }
  }, [_c('img', {
    staticClass: "d-inline-block align-top navbar-logo",
    staticStyle: {
      "object-fit": "contain"
    },
    attrs: {
      "src": require("@/assets/img/LogoWithText.png"),
      "width": "180px",
      "height": "60px",
      "alt": "giftdrop"
    }
  })])], 1)]), _c('b-col', {
    staticClass: "d-flex justify-content-center py-4 justify-content-lg-start align-items-center gap-1 pt-2",
    attrs: {
      "lg": "2"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/img/facebook-fill.png"),
      "width": "21px",
      "height": "21px",
      "alt": "facebook"
    }
  }), _c('img', {
    attrs: {
      "src": require("@/assets/img/twitter-fill.png"),
      "width": "21px",
      "height": "21px",
      "alt": "twitter"
    }
  }), _c('img', {
    attrs: {
      "src": require("@/assets/img/linkedin-fill.png"),
      "width": "21px",
      "height": "21px",
      "alt": "linkedin"
    }
  }), _c('img', {
    attrs: {
      "src": require("@/assets/img/youtube-fill.png"),
      "width": "21px",
      "height": "21px",
      "alt": "youtube"
    }
  })]), _c('b-col', {
    attrs: {
      "lg": "7"
    }
  }, [_c('b-nav', {
    staticClass: "nav-footer justify-content-lg-end",
    attrs: {
      "align": "center"
    }
  }, [_c('b-nav-item', {
    attrs: {
      "to": "/terms"
    }
  }, [_vm._v(" Terms of Service ")]), _c('b-nav-item', {
    attrs: {
      "to": "/privacy"
    }
  }, [_vm._v(" Privacy Policy ")]), _c('b-nav-item', {
    attrs: {
      "href": "mailto:support@giftdrop.com"
    }
  }, [_vm._v(" Support ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }