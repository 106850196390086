const ENDPOINTS = {
    USERS: `/users`,
    CAMPAIGNS: `/campaigns`,
    GIFTCARDS: `/giftcards`,
    CATALOG_MERCHANTS: `/catalog-merchants`,
    AVATARS: `/avatars`,
    GRATITUDES: `/gratitudes`,
    USERFEEDBACK: `/user-feedback`,
    CAMPAIGNSMETRICS: `/campaigns/metrics`,
    REFERRAL: `/referral`
};
export default ENDPOINTS;
