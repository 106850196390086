var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.baseComponent, {
    tag: "b-nav-item",
    staticClass: "nav-item",
    class: {
      active: _vm.isActive
    },
    attrs: {
      "to": _vm.link.path ? _vm.link.path : '/'
    }
  }, [_vm.isMenu ? _c('a', {
    staticClass: "sidebar-menu-item nav-link",
    class: {
      active: _vm.isActive
    },
    attrs: {
      "aria-expanded": !_vm.collapsed,
      "data-toggle": "collapse"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.collapseMenu.apply(null, arguments);
      }
    }
  }, [_vm.addLink ? [_c('span', {
    staticClass: "nav-link-text"
  }, [_vm._v(" " + _vm._s(_vm.link.name) + " "), _c('b', {
    staticClass: "caret"
  })])] : [_c('b-icon', {
    class: _vm.link.iconStyle,
    attrs: {
      "icon": _vm.link.icon
    }
  }), _c('span', {
    staticClass: "nav-link-text"
  }, [_vm._v(_vm._s(_vm.link.name) + " "), _c('b', {
    staticClass: "caret"
  })])]], 2) : _vm._e(), _c('collapse-transition', [_vm.$slots.default || this.isMenu ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.collapsed,
      expression: "!collapsed"
    }],
    staticClass: "collapse show"
  }, [_c('ul', {
    staticClass: "nav nav-sm flex-column"
  }, [_vm._t("default")], 2)]) : _vm._e()]), _vm.children.length === 0 && !_vm.$slots.default && _vm.link.path ? _vm._t("title", function () {
    return [_c(_vm.elementType(_vm.link, false), {
      tag: "component",
      staticClass: "nav-link",
      class: {
        active: _vm.link.active
      },
      attrs: {
        "to": _vm.link.path,
        "target": _vm.link.target,
        "href": _vm.link.path
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.linkClick.apply(null, arguments);
        }
      }
    }, [_vm.addLink ? [_c('span', {
      staticClass: "nav-link-text"
    }, [_vm._v(_vm._s(_vm.link.name))])] : [_vm.link.icon ? _c('b-icon', {
      class: _vm.link.iconStyle,
      attrs: {
        "icon": _vm.link.icon
      }
    }) : _vm._e(), _vm.link.vIcon ? _c('v-icon', {
      class: _vm.link.iconStyle,
      attrs: {
        "name": _vm.link.vIcon
      }
    }) : _vm._e(), _c('span', {
      staticClass: "nav-link-text"
    }, [_vm._v(_vm._s(_vm.link.name))])]], 2)];
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }