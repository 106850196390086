var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('base-header', {
    staticClass: "pb-6 profile-header"
  }, [_c('span', {
    staticClass: "mask bg-gradient-primary opacity-8"
  }), _c('b-row', {
    staticClass: "py-4",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "7",
      "lg": "6"
    }
  }, [_c('nav', {
    staticClass: "d-none d-md-inline-block",
    attrs: {
      "aria-label": "breadcrumb"
    }
  }, [_c('RouteBreadCrumb')], 1)])], 1), _c('b-row', {
    staticClass: "my-1"
  }, [!_vm.loading && _vm.company ? _c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('h1', {
    staticClass: "display-2 text-white"
  }, [_vm._v(" " + _vm._s(_vm.company.name) + " ")]), _c('p', {
    staticClass: "text-light"
  }, [_vm._v(" " + _vm._s(_vm.company.id) + " ")])]) : _vm._e()], 1)], 1), _c('b-container', {
    staticClass: "mt--5"
  }, [_c('b-row', [_c('b-col', [_c('MerchantCompanyCard'), !_vm.loading && _vm.company ? _c('CompanyMembersList', {
    attrs: {
      "isPending": ""
    }
  }) : _vm._e(), !_vm.loading && _vm.company ? _c('CompanyMembersList') : _vm._e(), _vm.isAdmin && _vm.company ? _c('CampaignsListTable', {
    attrs: {
      "hasActions": false,
      "hasCompanyColumn": false,
      "companyId": _vm.company.id
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }