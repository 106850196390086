var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    attrs: {
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-v": "center"
          }
        }, [_c('b-col', [_c('h3', {
          staticClass: "mb-0"
        }, [_vm._v("Transactions")])])], 1)];
      },
      proxy: true
    }])
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "table-responsive align-items-center table-flush",
    attrs: {
      "data": _vm.allTransactions,
      "header-row-class-name": "thead-light"
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('span', [_vm._v(" " + _vm._s(row.description) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Created"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.moment(row.createdInMs).fromNow()) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Fee"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c('span', [_vm._v(" $" + _vm._s(row.fee) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row.status === 'INITIALIZED' ? _c('b-badge', {
          staticClass: "status ml-2 font-weight-500",
          attrs: {
            "pill": "",
            "variant": "success"
          }
        }, [_vm._v(" Initialized ")]) : _vm._e(), row.status === 'GYFT_ERROR' ? _c('b-badge', {
          staticClass: "status ml-2 font-weight-500",
          attrs: {
            "pill": "",
            "variant": "danger"
          }
        }, [_vm._v(" Gyft Error ")]) : _vm._e(), row.status === 'STRITE_ERROR' ? _c('b-badge', {
          staticClass: "status ml-2 font-weight-500",
          attrs: {
            "pill": "",
            "variant": "warning"
          }
        }, [_vm._v(" Stripe Error ")]) : _vm._e(), row.status === 'COMPLETE' ? _c('b-badge', {
          staticClass: "status ml-2 font-weight-500",
          attrs: {
            "pill": "",
            "variant": "primary"
          }
        }, [_vm._v(" Complete ")]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [row.type === 'CAMPAIGN_FEE' ? _c('b-badge', {
          staticClass: "status ml-2 font-weight-500",
          attrs: {
            "pill": "",
            "variant": "info"
          }
        }, [_vm._v(" Campaign Fee ")]) : _vm._e(), row.type === 'GIFT_CARD_PURCHASE_AND_CREATION_FEE' ? _c('b-badge', {
          staticClass: "status ml-2 font-weight-500",
          attrs: {
            "pill": "",
            "variant": "secondary"
          }
        }, [_vm._v(" Gift Card Purchase and Creation Fee ")]) : _vm._e(), row.type === 'REFERRAL_AWARD' ? _c('b-badge', {
          staticClass: "status ml-2 font-weight-500",
          attrs: {
            "pill": "",
            "variant": "success"
          }
        }, [_vm._v(" Referral Award ")]) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }