import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from '../pages/NotFoundPage.vue';
import LandingPage from '../pages/landing/LandingPage.vue';
import Login from '../pages/landing/Login.vue';
import Terms from '../pages/landing/Terms.vue';
import Privacy from '../pages/landing/Privacy.vue';
import CreateMerchantAccount from '../pages/landing/CreateMerchantAccount.vue';
import UserFeedback from '../pages/portal/UserFeedback.vue';
// giftcards
// users
// avatars
import AvatarManager from '../pages/avatars/AvatarManager.vue';
// gratitudes
import GratitudesManager from '../pages/gratitudes/GratitudesManager.vue';
// referral redirect analytics page
import ReferralRedirect from '../pages/landing/ReferralRedirect.vue';
// main portal Page
// import CompanyList from '../pages/companies/CompanyList.vue'
import CompanyDetails from '../pages/companies/CompanyDetails.vue';
import NewCompany from '../pages/companies/New.vue';
import ComingSoon from '../pages/ComingSoon.vue';
import TransactionList from '../pages/transactions/TransactionList.vue';
import GyftPanel from '../pages/gyft/GyftPanel.vue';
Vue.use(VueRouter);
// routes, lowercase names
const routes = [
    {
        path: '/',
        component: LandingPage,
        name: 'home'
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy
    },
    {
        path: '/create',
        name: 'create account',
        component: CreateMerchantAccount
    },
    {
        path: '/referral/:id',
        component: ReferralRedirect
    },
    {
        path: '/portal',
        component: () => import(/* webpackChunkName: "portal" */ '../pages/Portal.vue'),
        name: 'portal',
        meta: {
            requiresAuth: true,
            isLayout: true
        },
        children: [
            {
                path: 'dashboard',
                component: () => import(/* webpackChunkName: "dashboard" */ '../pages/MerchantDashboard.vue'),
                name: 'Dashboard'
            },
            {
                path: 'users',
                component: () => import(/* webpackChunkName: "users" */ '../pages/users/UserList.vue'),
                name: 'Users',
                meta: {
                    requiresAdmin: true
                }
            },
            {
                path: 'users/:id',
                component: () => import(/* webpackChunkName: "profile" */ '../pages/users/MerchantProfile.vue'),
                name: 'Merchant Account'
            },
            {
                path: 'users/mobile/:id',
                component: () => import(/* webpackChunkName: "profile" */ '../pages/users/UserDetails.vue'),
                name: 'User Details'
            },
            {
                path: 'companies/new',
                component: NewCompany,
                name: 'New Company'
            },
            {
                path: 'companies/:id',
                component: CompanyDetails,
                name: 'Company Details'
            },
            {
                path: 'companies',
                component: () => import(
                /* webpackChunkName: "companies" */ '../pages/companies/CompanyList.vue'),
                name: 'Companies'
            },
            {
                path: 'giftcards',
                component: () => import(
                /* webpackChunkName: "companies" */ '../pages/giftcards/GiftCardList.vue'),
                name: 'Gift Cards'
            },
            {
                path: 'drops',
                component: () => import(/* webpackChunkName: "drops" */ '../offers/pages/OffersList.vue'),
                name: 'Drops'
            },
            {
                path: 'user-feedback',
                name: 'User Feedback',
                component: UserFeedback
            },
            {
                path: 'soon',
                name: 'Under Construction',
                component: ComingSoon
            },
            {
                path: 'api',
                name: 'SwaggerUI',
                component: () => import(/* webpackChunkName: "api" */ '../pages/APISwaggerUI.vue')
            },
            {
                path: 'campaigns',
                meta: {
                    isLayout: true
                },
                component: () => import(
                /* webpackChunkName: "campaignsLayout" */ '../layout/CampaignsLayout.vue'),
                children: [
                    {
                        path: '',
                        name: 'Campaigns',
                        component: () => import(
                        /* webpackChunkName: "campaignList" */ '../pages/campaigns/CampaignList.vue')
                    },
                    // important to have this before :id, since if after 'create' will match id
                    {
                        path: 'create',
                        name: 'Create Campaign',
                        component: () => import(
                        /* webpackChunkName: "campaignCreate" */ '../pages/campaigns/CreateCampaign.vue')
                    },
                    {
                        path: ':id',
                        name: 'Campaign Details',
                        component: () => import(
                        /* webpackChunkName: "campaignDetails" */ '../pages/campaigns/CampaignDetails.vue')
                    }
                    // {
                    //     path: ':id/edit',
                    //     component: EditCampaign
                    // },
                    // {
                    //     path: ':id/metrics',
                    //     name: 'CampaignMetrics',
                    //     component: CampaignMetrics
                    // },
                    // {
                    //     path: ':id/stats',
                    //     name: 'CampaignStats',
                    //     component: CampaignStats
                    // },
                    // {
                    //     path: ':id/analytics',
                    //     name: 'CampaignAnalytics',
                    //     component: CampaignAnalytics
                    // }
                ]
            },
            // {
            //     path: 'giftcards',
            //     name: 'Gift Cards',
            //     component: GiftCardList
            // },
            // {
            //     path: 'catalog-merchants',
            //     name: 'catalogMerchants',
            //     component: MerchantCatalog
            // },
            // {
            //     path: 'catalog-merchants/:id/edit',
            //     component: Details,
            //     meta: {
            //         dashboardTitle: 'Editing Catalog Merchant'
            //     }
            // },
            // {
            //     path: '/catalog-merchants/:id/giftcards',
            //     name: 'catalogMerchantAddCards',
            //     component: CatalogMerchantGiftCards
            // },
            {
                path: 'avatars',
                name: 'Avatars',
                component: AvatarManager
            },
            {
                path: 'gratitudes',
                name: 'Gratitudes',
                component: GratitudesManager
            },
            {
                path: 'transactions',
                name: 'Transactions',
                component: TransactionList
            },
            {
                path: 'gyft',
                name: 'Gyft',
                component: GyftPanel
            }
        ]
    },
    { path: '*', component: NotFound }
];
// @logan not sure what this is, I think this will make the scroll position either
// stay or go to the top of the page, probably doesn't work
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    linkActiveClass: 'active',
    scrollBehavior: (to, from, savedPosition) => {
        // if (savedPosition) {
        //     return savedPosition
        // }
        // if (to.hash) {
        //     return { selector: to.hash }
        // }
        // return { x: 0, y: 0 }
        return {
            selector: '#app',
            behavior: 'smooth'
        };
    }
});
export default router;
